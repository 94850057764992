//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #00A0E9;//メインカラー
$sub_c:     #054497;//サブカラー
$point_c:   #FFF237;//ポイントカラー
$hover_c:   #008bf5;//ホバーカラー
$txt_c:     #222222;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other color
$other_c1:  #1F56A9;//アザーカラー1
$other_c2:  #84D8FF;//アザーカラー2
$other_c3:  #729ee0;//アザーカラー3

// backgraound
$bg_c1:   #DFEFFE;//バックグラウンドカラー1
$bg_c2:   #C8E2F9;//バックグラウンドカラー2
$bg_c3:   #EEF5FA;//バックグラウンドカラー3
$bg_c4:   #F6F2D7;//バックグラウンドカラー4
$bg_c5:   #F2F7FF;//バックグラウンドカラー4

// mono tone
$white:     #ffffff;   //ホワイト
$o-white:   #fdfdfd;   //オフホワイト
$p-gray:    #F6F6F6;   //ペールグレー
$l-gray:    #E5E9EC;   //ライトグレー
$m-gray:    #C9CED2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #091934;   //ブラックグレー
$black:     #000000;   //ブラック

// color tone
$red:       #D93838;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク


// loader color
$bg_loader: $main_c;//ローダー時の背景色、及びページ遷移時の色