@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
@mixin col($col-break, $col-count, $gutter: margin()) {
	$cont-s: inherit;
	@if $col-break == xs {
		$cont-s: map-get($grid-num, xs);
	} @else if $col-break == sm {
		$cont-s: map-get($grid-num, sm);
	} @else if $col-break == md {
		$cont-s: map-get($grid-num, md);
	} @else if $col-break == lg {
		$cont-s: map-get($grid-num, lg);
	} @else if $col-break == xl {
		$cont-s: map-get($grid-num, xl);
	}
	$num: $col-count - 1;
	$margin: floor-decimal(calc(($gutter * $num) / $cont-s) * 100%, 2);
	column-gap: floor-decimal(calc($margin / $num), 2);
	& > * {
		flex-basis: floor-decimal(calc((100% - $margin) / $col-count));
	}
}


/* 2column */
.l-col-2 {
	&-sm {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			@include col(xl, 2, 80);
			row-gap: 3vw;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
}

.l-col-3 {
	&-sm {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			@include col(sm, 2, 30);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				width: 100%;
				@include m-a;
			}
		}
	}
	&-sm2 {
		//ブログ用
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			@include col(sm, 2, 30);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 30);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
	&-md {
		display: flex;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			@include col(md, 2, 30);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
	&-lg {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
}

.l-col-4 {
	@include flex-wrap;
	@include col(xs, 2, 20);
	row-gap: 5vw;
	@include media-breakpoint-up(lg) {
		@include col(lg, 4, 20);
	}
	@include media-breakpoint-up(xl) {
		@include col(xl, 4, 30);
		row-gap: 3rem;
	}
	&-xs {
		display: flex;
		@include media-breakpoint-up(xs) {
			flex-wrap: wrap;
			@include col(xs, 2, 40);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 30);
			row-gap: 2.5rem;
		}
		@include media-breakpoint-down(xxs) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
	&-sm {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-wrap: wrap;
			@include col(sm, 2, 40);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 30);
			row-gap: 2.5rem;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
	&-md {
		display: flex;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			@include col(md, 2, 30);
			row-gap: 3vw;
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 30);
			row-gap: 2.5rem;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
			& > * {
				@include m-a;
			}
		}
	}
}
