@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1676px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1675px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1675px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/* ========================================
 * foundation
 ======================================= */
/* html5reset-1.6.1.css */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, address,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse; /* added */
  -webkit-text-size-adjust: none; /* added */
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

address {
  font-style: normal;
}

nav ul {
  list-style: none;
}

ul li {
  list-style: none;
} /* added */
ol li {
  list-style: none;
} /* added */
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default; /* 1 */
  line-height: 1.5; /* 2 */
  -moz-tab-size: 4; /* 3 */
  tab-size: 4; /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  word-break: break-word; /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  margin: 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
  appearance: none;
  font-family: inherit;
  font-weight: normal;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
  outline: none;
  padding: 0;
  appearance: none;
  cursor: pointer;
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0; /* 1 */
  padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation; /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?urenkz");
  src: url("../fonts/icomoon.eot?urenkz#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?urenkz") format("truetype"), url("../fonts/icomoon.woff?urenkz") format("woff"), url("../fonts/icomoon.svg?urenkz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow1_top:before {
  content: "\e316";
}

.icon-arrow1_right:before {
  content: "\e315";
}

.icon-arrow1_bottom:before {
  content: "\e313";
}

.icon-arrow1_left:before {
  content: "\e314";
}

.icon-arrow2_top:before {
  content: "\e907";
}

.icon-arrow2_right:before {
  content: "\e901";
}

.icon-arrow2_bottom:before {
  content: "\e906";
}

.icon-arrow2_left:before {
  content: "\e902";
}

.icon-direction_arrow_top:before {
  content: "\e913";
}

.icon-direction_arrow_right:before {
  content: "\e914";
}

.icon-direction_arrow_bottom:before {
  content: "\e915";
}

.icon-direction_arrow_left:before {
  content: "\e916";
}

.icon-home:before {
  content: "\e90c";
}

.icon-phone:before {
  content: "\e909";
}

.icon-mail:before {
  content: "\e911";
}

.icon-search:before {
  content: "\e905";
}

.icon-search-plus:before {
  content: "\e908";
}

.icon-link:before {
  content: "\e900";
}

.icon-pdf:before {
  content: "\e903";
}

.icon-tag:before {
  content: "\e904";
}

.icon-lock:before {
  content: "\e90f";
}

.icon-facebook1:before {
  content: "\e90a";
}

.icon-facebook2:before {
  content: "\e912";
}

.icon-x:before {
  content: "\e90b";
}

.icon-instagram:before {
  content: "\e90d";
}

.icon-line:before {
  content: "\e90e";
}

.icon-youtube:before {
  content: "\e910";
}

.icon-tiktok:before {
  content: "\e917";
}

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/
html {
  font-size: 87.5%;
}
@media (min-width: 768px) {
  html {
    font-size: 93.75%;
  }
}
@media (min-width: 1176px) {
  html {
    font-size: 100%;
  }
}
@media (min-width: 1921px) {
  html {
    font-size: 0.83vw;
  }
}

body {
  font-size: 1rem;
  word-wrap: break-word;
  color: #222222;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  background: #00A0E9;
  font-family: 'Zen Kaku Gothic New', "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
  line-height: 1.75;
  font-weight: 500;
  scrollbar-width: thin;
  scrollbar-color: #C9CED2 #ffffff;
  letter-spacing: 0.1em;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background: #F6F6F6;
}
body::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #ffffff;
}
@media (min-width: 1921px) {
  body {
    font-size: 0.83vw;
  }
}

::-webkit-input-placeholder {
  font-family: 'Zen Kaku Gothic New', "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
}

:-moz-placeholder {
  font-family: 'Zen Kaku Gothic New', "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
}

::selection {
  background: #00A0E9;
  color: #ffffff;
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/
/* heading 
----------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  line-height: 1.4;
}

/* forn parts
----------------------------------------------------------------*/
input {
  font-size: 0.94em;
}

select {
  font-size: 0.94em;
}

textarea {
  font-size: 0.94em;
}

/* a,img
----------------------------------------------------------------*/
a {
  color: #00A0E9;
  vertical-align: top;
}
a:link, a:visited {
  text-decoration: underline;
}
a:hover, a:active {
  text-decoration: none;
}
a img {
  display: block;
  width: 100%;
}
a.hover img {
  transition: all 0.3s ease 0s;
}
a.hover img:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
  display: block;
}

/* tel-link */
.tel-link a {
  text-decoration: none !important;
}

/* scroll-position */
[id*=link-],
.scroll-position {
  padding-top: calc(1em + 64px);
  margin-top: calc(-1em - 64px);
}
@media (min-width: 1553px) {
  [id*=link-],
  .scroll-position {
    padding-top: 6rem;
    margin-top: -6rem;
  }
}

/* scroll-bar */
.scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: #C9CED2 #ffffff;
}
.scroll-bar::-webkit-scrollbar {
  width: 8px;
}
.scroll-bar::-webkit-scrollbar-track {
  background: #F6F6F6;
}
.scroll-bar::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #ffffff;
}

/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/
.loader {
  width: 100vw;
  height: 100vh;
  background: #00A0E9;
  z-index: 13;
  position: fixed;
  transform-origin: right;
}
.loader.is-active {
  transform: scaleX(100);
  transform-origin: left;
}
.loader-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
}
.loader-inner.is-active {
  display: flex;
}
.loader-circle {
  width: 0;
  height: 0;
  position: absolute;
  display: block;
  background: #ffffff;
}
.loader-filter {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 12;
  transform-origin: right;
}
.loader-filter.is-active {
  transform: scaleX(100);
  transform-origin: left;
}

/* spinner */
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* sk-chase */
.sk-chase {
  width: 48px;
  height: 48px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}
.sk-chase-dot::before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #ffffff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1), .sk-chase-dot:nth-child(1)::before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2), .sk-chase-dot:nth-child(2)::before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3), .sk-chase-dot:nth-child(3)::before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4), .sk-chase-dot:nth-child(4)::before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5), .sk-chase-dot:nth-child(5)::before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6), .sk-chase-dot:nth-child(6)::before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1);
  }
}
/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  background: transparent;
  transition: all 0.3s ease-in 0s;
}
@media (max-width: 1295px) {
  .header {
    height: 64px;
  }
}
.header.js-fixed {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.header.js-fixed .h-left {
  padding-top: 0.75em;
}
@media (min-width: 1296px) {
  .header.js-fixed .h-left {
    max-width: 200px;
  }
}
@media (min-width: 1553px) {
  .header.js-fixed .h-left {
    max-width: 220px;
  }
}
@media (max-width: 1295px) {
  .header.js-fixed .h-left {
    max-width: 188px;
  }
}

.h-inner {
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
}
.h-flex {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.h-left {
  width: 70%;
  padding-top: 1em;
  margin-left: 2%;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1296px) {
  .h-left {
    max-width: 280px;
  }
}
@media (max-width: 1295px) {
  .h-left {
    max-width: 188px;
  }
}
@media (min-width: 1921px) {
  .h-left {
    max-width: 14.58vw;
  }
}
.h-logo {
  letter-spacing: 0;
  text-align: left;
}
.h-right {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1295px) {
  .h-right {
    display: none;
  }
}
.h-donation a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1.5em;
  height: 100%;
  min-height: 70px;
  color: #ffffff;
  background: #00A0E9;
  font-size: 0.94em;
  line-height: 1;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
}
@media (min-width: 1553px) {
  .h-donation a {
    min-height: 80px;
  }
}
@media (min-width: 1676px) {
  .h-donation a {
    font-size: 1.07em;
  }
}
.h-donation a span {
  font-weight: 700;
  padding-right: 0.5em;
}
.h-donation a i {
  position: relative;
  width: 32px;
  height: 32px;
  content: "";
  background: #ffffff;
  border-radius: 50%;
}
.h-donation a i::before {
  color: #00A0E9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}
.h-donation a:hover {
  background: #008bf5;
}
.h-donation a:hover i::before {
  color: #008bf5;
}

/*--------------------------------------------------------------------/
	navigation
/--------------------------------------------------------------------*/
@media (max-width: lg) {
  .pc-nav {
    display: none;
  }
}

.pc-nav__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 60px;
}
@media (min-width: 1553px) {
  .pc-nav__list {
    min-height: 80px;
  }
}
.pc-nav__list > li > a {
  vertical-align: middle;
  color: #222222;
  padding: 1em 1.5em;
  height: 100%;
  min-height: 70px;
  position: relative;
  font-size: 0.94em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  line-height: 1;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
}
@media (min-width: 1553px) {
  .pc-nav__list > li > a {
    padding: 1em 1.5em;
    min-height: 80px;
  }
}
@media (min-width: 1676px) {
  .pc-nav__list > li > a {
    font-size: 1.07em;
  }
}
.pc-nav__list > li > a:hover {
  color: #ffffff;
  background: #008bf5;
}

.pc-lv2 {
  position: relative;
}
.pc-lv2:hover .sub-menu {
  top: 80px;
  visibility: visible;
  opacity: 1;
}
.pc-lv2 .sub-menu {
  position: absolute;
  top: 60px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  left: 0;
  z-index: 10;
  width: 100%;
  min-width: 240px;
  transition: all 0.3s ease 0s;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1553px) {
  .pc-lv2 .sub-menu {
    top: 80px;
  }
}
@media (min-width: 1921px) {
  .pc-lv2 .sub-menu {
    width: 12.5vw;
  }
}
.pc-lv2 .sub-menu li {
  width: auto;
}
.pc-lv2 .sub-menu a {
  display: flex;
  align-items: center;
  font-size: 0.94em;
  padding: 0.75em 1.25em;
  color: #ffffff;
  line-height: 1.4;
  position: relative;
  background: #091934;
  height: 50px;
  text-decoration: none !important;
  font-weight: 700;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1553px) {
  .pc-lv2 .sub-menu a {
    height: 60px;
  }
}
.pc-lv2 .sub-menu a:hover {
  background: #008bf5;
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	modal
/--------------------------------------------------------------------*/
.body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateX(-12px);
    opacity: 0;
  }
  75% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes menu-bar03 {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
@keyframes active-menu-bar02 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    transform: translateX(12px);
    opacity: 0;
  }
  100% {
    transform: translateX(12px);
    opacity: 0;
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}
/* スマホメニュー */
@media (max-width: 1295px) {
  .close-btn a {
    text-decoration: none !important;
  }
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
}
@media (min-width: 1296px) {
  .hamburger {
    display: none;
  }
}
.hamburger-btn {
  position: relative;
  width: 64px;
  height: 64px;
  padding: 8px;
  cursor: pointer;
  display: block;
  transition: all 0.4s;
  box-sizing: border-box;
  background: #00A0E9;
  transition: all 0.3s ease 0s;
}
.hamburger-btn:hover {
  background: #008bf5;
}
.hamburger-btn span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  position: absolute;
  left: 16px;
  width: 32px;
  height: 1px;
  background-color: #ffffff;
}
.hamburger-btn span:nth-of-type(1) {
  top: 21px;
  animation: menu-bar01 0.5s forwards;
}
.hamburger-btn span:nth-of-type(2) {
  top: 31px;
  animation: menu-bar02 0.5s forwards;
}
.hamburger-btn span:nth-of-type(3) {
  top: 41px;
  animation: menu-bar03 0.5s forwards;
}
.hamburger-btn.is-active span:nth-of-type(1) {
  animation: active-menu-bar01 0.5s forwards;
}
.hamburger-btn.is-active span:nth-child(2) {
  animation: active-menu-bar02 0.5s forwards;
}
.hamburger-btn.is-active span:nth-of-type(3) {
  animation: active-menu-bar03 0.5s forwards;
}

@media (min-width: 1296px) {
  .sp-nav {
    display: none;
  }
}
@media (max-width: 1295px) {
  .sp-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fdfdfd;
    visibility: hidden;
    z-index: 9;
  }
  body.open .sp-nav {
    cursor: "", auto;
    transition: 0.5 0.3s ease 0s;
    visibility: visible;
    opacity: 1;
  }
}
.sp-nav__inner {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding-bottom: clamp(4rem, 6vw, 6rem);
}
.sp-nav__head {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background: #ffffff;
  width: 100%;
  z-index: 1;
}
.sp-nav__head.js-fixed .sp-nav__ttl {
  padding-top: 0.75em;
}
.sp-nav__ttl {
  width: 70%;
  padding-top: 1em;
  margin-left: 2%;
}
@media (min-width: 1296px) {
  .sp-nav__ttl {
    max-width: 280px;
  }
}
@media (max-width: 1295px) {
  .sp-nav__ttl {
    max-width: 188px;
  }
}
.sp-nav__cont {
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .sp-nav__cont {
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.sp-nav__list > li {
  width: 100%;
  margin: 0 auto;
  margin-top: -15px;
  text-align: left;
  opacity: 0;
  border-bottom: 1px solid #C9CED2;
  line-height: 1.5em;
  width: 100%;
}
.sp-nav__list > li a {
  display: block;
  color: #222222;
  text-decoration: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.5em 5%;
  position: relative;
  font-size: 1.14em;
  font-weight: 700;
}
@media (min-width: 768px) {
  .sp-nav__list > li a {
    padding: 1.5em 0;
  }
}

.sp-lv2 {
  position: relative;
  width: 100%;
}
.sp-lv2 > a[href="javascript:void(0);"]::before, .sp-lv2 > a[href="javascript:void(0);"]::after {
  font-style: normal;
  position: absolute;
  top: 50%;
  right: 1em;
  display: block;
  width: 14px;
  height: 1px;
  font-size: 1.14em;
  content: "";
  background-color: #979DA4;
  transition: all 0.3s ease 0s;
}
.sp-lv2 > a[href="javascript:void(0);"]::before {
  transform: rotate(0deg);
}
.sp-lv2 > a[href="javascript:void(0);"]::after {
  transform: rotate(90deg);
}
.sp-lv2 > a[href="javascript:void(0);"].is-active::before {
  display: none;
}
.sp-lv2 > a[href="javascript:void(0);"].is-active::after {
  top: 50%;
  transform: rotate(180deg);
}
.sp-lv2 .sub-menu {
  display: none;
}
.sp-lv2 .sub-menu li {
  border-top: 1px solid #ffffff;
  background: #091934;
}
.sp-lv2 .sub-menu li a {
  color: #ffffff;
  padding-left: calc(5% + 1.4em);
  position: relative;
}
@media (min-width: 768px) {
  .sp-lv2 .sub-menu li a {
    padding-left: 2.9em;
  }
}
.sp-lv2 .sub-menu li a::before {
  position: absolute;
  content: "";
  top: 2.25em;
  left: 5%;
  width: 10px;
  height: 1px;
  background: #ffffff;
}
@media (min-width: 768px) {
  .sp-lv2 .sub-menu li a::before {
    left: 1.5em;
  }
}

body.open .sp-nav__list > li {
  opacity: 1;
  margin-top: 0;
}
body.open .sp-nav__list > li:nth-child(1) {
  transition: 0.5s ease 0.3s;
}
body.open .sp-nav__list > li:nth-child(2) {
  transition: 0.5s ease 0.4s;
}
body.open .sp-nav__list > li:nth-child(3) {
  transition: 0.5s ease 0.5s;
}
body.open .sp-nav__list > li:nth-child(4) {
  transition: 0.5s ease 0.6s;
}
body.open .sp-nav__list > li:nth-child(5) {
  transition: 0.5s ease 0.7s;
}
body.open .sp-nav__list > li:nth-child(6) {
  transition: 0.5s ease 0.8s;
}
body.open .sp-nav__list > li:nth-child(7) {
  transition: 0.5s ease 0.9s;
}
body.open .sp-nav__list > li:nth-child(8) {
  transition: 0.5s ease 1s;
}
body.open .sp-nav__list > li:nth-child(9) {
  transition: 0.5s ease 1.1s;
}
body.open .sp-nav__list > li:nth-child(10) {
  transition: 0.5s ease 1.2s;
}
body.open .sp-nav__list > li:nth-child(11) {
  transition: 0.5s ease 1.3s;
}

.sp-nav__donation {
  margin-top: -15px;
  padding-top: 1em;
  width: 90%;
  opacity: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .sp-nav__donation {
    width: 100%;
  }
}
body.open .sp-nav__donation {
  opacity: 1;
  margin-top: 0;
  transition: all 0.5s ease 1.2s;
}
.sp-nav__donation a {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #00A0E9;
  border: 1px solid;
  padding: 1.25em 4%;
  width: 100%;
  font-size: 1.14em;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
}
@media (min-width: 1553px) {
  .sp-nav__donation a {
    min-height: 80px;
  }
}
.sp-nav__donation a:hover {
  background: #008bf5;
}
.sp-nav__donation a:hover i::before {
  color: #008bf5;
}
.sp-nav__donation a::before {
  content: "";
  justify-self: start;
}
.sp-nav__donation a span {
  font-weight: 700;
}
.sp-nav__donation a i {
  position: relative;
  width: 32px;
  height: 32px;
  content: "";
  background: #ffffff;
  justify-self: end;
  grid-column: 3/4;
  border-radius: 50%;
}
.sp-nav__donation a i::before {
  color: #00A0E9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}

.sp-nav__close {
  margin-top: 2rem;
  opacity: 0;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  .sp-nav__close {
    width: 90%;
  }
}
body.open .sp-nav__close {
  opacity: 1;
  transition: all 0.5s ease 1.4s;
}
.sp-nav__close a {
  text-align: center;
  border: 1px solid #979DA4;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #979DA4;
  height: 80px;
  line-height: 80px;
  background: #ffffff;
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 700;
  text-decoration: none !important;
  font-family: 'Poppins', sans-serif;
  border-radius: 999px;
}
.sp-nav__close a::before {
  content: "×";
  margin-right: 0.3rem;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */
#wrapper {
  background: #ffffff;
}

/* main */
.main {
  background: #ffffff;
}
.main::after {
  display: block;
  content: "";
  clear: both;
}
.main img {
  display: block;
}

/* contents */
.l-cont_lg {
  max-width: 1080px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 768px) {
  .l-cont_lg {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .l-cont_lg {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1921px) {
  .l-cont_lg {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 66.25vw;
  }
}

.l-cont {
  max-width: 1536px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 768px) {
  .l-cont {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .l-cont {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1921px) {
  .l-cont {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 85vw;
  }
}

.l-cont_xxl {
  max-width: 1736px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 768px) {
  .l-cont_xxl {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .l-cont_xxl {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1921px) {
  .l-cont_xxl {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 92.29vw;
  }
}

.l-cont_xxxl {
  max-width: 1824px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 768px) {
  .l-cont_xxxl {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .l-cont_xxxl {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (min-width: 1921px) {
  .l-cont_xxxl {
    padding-left: 5vw;
    padding-right: 5vw;
    max-width: 100vw;
  }
}

.l-cont_full {
  max-width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 5%;
  padding-right: 5%;
}
@media (min-width: 768px) {
  .l-cont_full {
    padding-left: 6%;
    padding-right: 6%;
  }
}
@media (min-width: 1553px) {
  .l-cont_full {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

/* contents-box */
.l-box {
  width: 100%;
  z-index: 1;
  position: relative;
}
.l-box:not(:last-of-type) {
  margin-bottom: 8rem;
}
@media (min-width: 1176px) {
  .l-box:not(:last-of-type) {
    margin-bottom: 10rem;
  }
}

@media (min-width: 1176px) {
  .common-flex__list.is-col2 .c-box1__date {
    font-size: 1.25em;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 .c-box1__category {
    font-size: 1.25em;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col2 .c-box1__ttl {
    font-size: 1.38rem;
  }
}

.voice-box a {
  position: relative;
  color: #222222;
  height: 100%;
  display: block;
  background: #ffffff;
  padding-bottom: 3rem;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.voice-box a:hover i {
  background: #008bf5;
}
.voice-box__img {
  overflow: hidden;
  display: block;
  margin-bottom: 0.75em;
  width: 100%;
  aspect-ratio: 5/3;
}
.voice-box__ttl {
  font-size: 1.14em;
  margin-bottom: 0.5em;
  font-weight: 700;
}
@media (min-width: 576px) {
  .voice-box__txt {
    font-size: 0.85em;
  }
}
.voice-box__btn {
  position: absolute;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
}
.voice-box__btn::before {
  content: "";
  justify-self: start;
}
.voice-box__btn i {
  position: relative;
  width: 32px;
  height: 32px;
  content: "";
  background: #00A0E9;
  justify-self: end;
  transition: all 0.3s ease 0s;
  border-radius: 50%;
}
.voice-box__btn i::before {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}

/*----------------------------------------------------------------
	component(common)
----------------------------------------------------------------*/
/*	heading
/--------------------------------------------------------------------*/
.cont-ttl b {
  position: relative;
  display: block;
  font-size: calc(1.5rem + 20 * (100vw - 280px) / 1300);
  line-height: 1.2;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .cont-ttl b {
    font-size: calc(1.5rem + 20 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .cont-ttl b {
    font-size: 3rem;
  }
}
.cont-ttl b::before {
  position: absolute;
  top: 50%;
  right: calc(100% + 0.5em);
  content: "";
  width: 100vw;
  height: 2px;
  background: #00A0E9;
}
.cont-ttl em {
  display: block;
  font-style: normal;
  color: #00A0E9;
  margin-top: 0.5em;
  line-height: 1.2;
}
@media (min-width: 1176px) {
  .cont-ttl em {
    font-size: calc(0.9rem + 8 * (100vw - 768px) / 812);
  }
}
@media (min-width: 1553px) {
  .cont-ttl em {
    font-size: 1.5rem;
  }
}

.heading-ttl {
  line-height: 1.4;
  padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
  margin-bottom: calc(2rem + 0.5em);
  position: relative;
  text-align: center;
  font-size: calc(1.25rem + 12 * (100vw - 376px) / 1204);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .heading-ttl {
    font-size: 2rem;
  }
}
.heading-ttl::before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 3px;
  bottom: 0;
  left: calc(50% - 2rem);
  border-radius: 2px;
  background: #00A0E9;
}

.heading-ttl2 {
  line-height: 1.4;
  padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
  margin-bottom: calc(2rem + 0.5em);
  position: relative;
  text-align: center;
  font-size: calc(1.14rem + 8 * (100vw - 376px) / 1204);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .heading-ttl2 {
    font-size: 1.63rem;
  }
}
.heading-ttl2::before {
  content: "";
  position: absolute;
  width: 4rem;
  height: 3px;
  bottom: 0;
  left: calc(50% - 2rem);
  border-radius: 2px;
  background: #00A0E9;
}

/* teble用のタイトル */
.table-ttl {
  padding: 1em 1.5em;
  background: #F6F6F6;
  line-height: 1.5;
}

/* ol
/--------------------------------------------------------------------*/
.common-ol {
  margin-left: 5px;
  counter-reset: ol-num;
}
.common-ol > li {
  line-height: 1.4;
  padding-left: 1.5em;
  text-indent: -1.5em;
  position: relative;
}
.common-ol > li::before {
  counter-increment: ol-num;
  content: counter(ol-num) ".";
  padding-right: 0.5em;
  text-align: center;
}
.common-ol > li:not(:last-child) {
  margin-bottom: 0.7rem;
}

/* ul
/--------------------------------------------------------------------*/
.common-ul, .is-wysiwyg ul {
  text-align: left;
  row-gap: 0.5em;
  display: flex;
  flex-direction: column;
}
.common-ul.is-main li::before, .is-wysiwyg ul.is-main li::before {
  color: #00A0E9;
}
.common-ul li, .is-wysiwyg ul li {
  line-height: 1.5;
  padding-left: 1.5rem;
  position: relative;
}
.common-ul li::before, .is-wysiwyg ul li::before {
  content: "●";
  color: #00A0E9;
  position: absolute;
  left: 0;
  top: 0.35em;
  font-size: 0.7em;
}

.common-ul2 {
  margin-left: 5px;
  text-align: left;
  row-gap: 0.5em;
  display: flex;
  flex-direction: column;
}
.common-ul2 li {
  line-height: 1.5;
  padding-left: 1rem;
  position: relative;
}
.common-ul2 li::before {
  content: "・";
  position: absolute;
  color: #222222;
  left: 0;
  top: 0;
}

.caution-ul {
  margin-left: 5px;
  row-gap: 0.5em;
  display: flex;
  flex-direction: column;
}
.caution-ul li {
  line-height: 1.5;
  padding-left: 1.2rem;
  position: relative;
}
.caution-ul li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
  margin-left: 5px;
  margin-bottom: 2rem;
}
.common-dl dt {
  line-height: 1.4;
}
.common-dl dt:before {
  content: "●";
  padding-right: 0.3em;
}
.common-dl dd:not(:last-child) {
  margin-bottom: 1.5rem;
}

.common-dl2 {
  margin-bottom: 1rem;
}
.common-dl2 dt {
  line-height: 1.4;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #C9CED2;
  margin-bottom: 0.7rem;
  font-size: 1em;
  font-weight: 500;
  font-size: 1.14em;
}
.common-dl2 dd:not(:last-child) {
  margin-bottom: 2rem;
}

/* table
----------------------------------------------------------------*/
.common-table {
  width: 100%;
  font-size: 0.9375em;
}
.common-table tr {
  border-bottom: 1px solid #C9CED2;
}
.common-table tr:first-child {
  border-top: 1px solid #C9CED2;
}
.common-table th, .common-table td.column-1 {
  text-align: left;
  padding: 1em 0;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .common-table th, .common-table td.column-1 {
    display: table-cell;
    width: 25%;
    min-width: 180px;
    padding: 1.25em 0;
  }
}
@media (min-width: 1553px) {
  .common-table th, .common-table td.column-1 {
    width: 240px;
  }
}
@media (max-width: 1175px) {
  .common-table th, .common-table td.column-1 {
    display: block;
    width: 100%;
    padding-bottom: 0;
  }
}
.common-table td.column-2 {
  vertical-align: center;
}
@media (min-width: 768px) {
  .common-table td.column-2 {
    display: table-cell;
    padding: 1em 1.25em 1em 0.5em;
  }
}
@media (max-width: 767px) {
  .common-table td.column-2 {
    display: block;
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 1em;
  }
}

.common-table2 {
  width: 100%;
  font-size: 0.9375em;
}
.common-table2 tr {
  border-bottom: 1px solid #C9CED2;
}
.common-table2 tr:first-child {
  border-top: 1px solid #C9CED2;
}
.common-table2 th, .common-table2 td.column-1 {
  text-align: left;
  padding: 1em 1.25em;
  background: #F6F6F6;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .common-table2 th, .common-table2 td.column-1 {
    padding: 1em 1.25em;
    width: 25%;
    min-width: 180px;
  }
}
@media (min-width: 1553px) {
  .common-table2 th, .common-table2 td.column-1 {
    width: 240px;
  }
}
@media (max-width: 1175px) {
  .common-table2 th, .common-table2 td.column-1 {
    display: block;
  }
}
.common-table2 td {
  padding: 1em 1.25em;
}
@media (min-width: 1176px) {
  .common-table2 td {
    padding: 1em 1.25em;
  }
}
@media (max-width: 1175px) {
  .common-table2 td {
    display: block;
  }
}

.president-table {
  width: 100%;
  line-height: 1.5;
}
@media (min-width: 1553px) {
  .president-table {
    font-size: 1.14em;
  }
}
.president-table th {
  display: block;
  padding: 0.25em 0;
}
.president-table td {
  display: block;
}
.president-table td.column-1 {
  position: relative;
  padding-left: 1em;
  border-left: 3px solid #054497;
  font-weight: 700;
}
.president-table td.column-2 {
  padding-top: 1em;
  padding-bottom: 1.5em;
}

/* table press CSS */
.tablepress td,
.tablepress th {
  padding: none;
}

.tablepress tbody td,
.tablepress tfoot th {
  border-top: none !important;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
@media (min-width: 1176px) {
  .common-category__ttl {
    display: none;
  }
}
@media (max-width: 1175px) {
  .common-category__ttl {
    padding: 0.3rem 0 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #C9CED2;
    font-size: 1.11em;
    font-weight: 500;
  }
}
.common-category__list {
  display: flex;
}
@media (min-width: 1176px) {
  .common-category__list {
    flex-wrap: wrap;
    gap: 0.75em 0.75em;
    align-items: flex-end;
  }
}
@media (max-width: 1175px) {
  .common-category__list {
    flex-direction: column;
  }
}
@media (min-width: 1176px) {
  .common-category__list li {
    width: calc(20% - 0.6em);
    flex-shrink: 1;
  }
}
@media (max-width: 1175px) {
  .common-category__list li {
    border-bottom: 1px solid #E5E9EC;
  }
}
.common-category__list li a {
  color: #222222;
  display: flex;
  column-gap: 0.5em;
  position: relative;
  text-align: center;
  display: block;
  line-height: 1.2;
  font-weight: 700;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1176px) {
  .common-category__list li a {
    padding: 0.5em 0.2em 0.8em;
    border-bottom: 0.3em solid #C9CED2;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a {
    padding: 0.75em 0.5em 0.75em 0.2em;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    background: #00A0E9;
    transition: all 0.3s ease 0s;
  }
}
@media (min-width: 1176px) {
  .common-category__list li a:hover {
    border-color: #008bf5;
    color: #008bf5;
  }
}
@media (max-width: 1175px) {
  .common-category__list li a:hover::before {
    width: 100%;
  }
}
.common-category__list li a .is-text {
  font-weight: 700;
}

ul.select-list.is-active {
  position: relative;
  top: -1px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #C9CED2;
  padding: 0.75em 1em 1.25em;
  z-index: 2;
}

.select-display {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #C9CED2;
  padding: 1em;
  position: relative;
  background: #ffffff;
}
.select-display__btn {
  display: block;
  position: absolute;
  top: calc(50% - 16px);
  right: 1rem;
  width: 32px;
  height: 32px;
  transition: all 0.3s ease 0s;
}
.select-display__btn i {
  color: #979DA4;
  font-size: 2rem;
  line-height: 1;
}
.select-display.is-active .select-display__btn {
  transform: rotate(180deg);
}
.select-display .is-text {
  display: block;
  width: 85%;
}

/* cotegory */
.category .category-item {
  font-size: 0.81rem;
  padding: 0.3rem 1rem;
  min-width: 140px;
  vertical-align: top;
  margin-right: 0.3rem;
  margin-bottom: 0.4rem;
}

[class*=category-item] {
  display: inline-block;
  background-color: #F6F6F6;
  color: #00A0E9;
  text-align: center;
  line-height: 1.4;
  padding: 0.4em 1.25em;
  font-size: 0.85em;
  letter-spacing: 0.05em;
  border-radius: 999px;
  font-weight: 600;
}
a[class*=category-item] {
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
a[class*=category-item]:hover {
  filter: saturate(200%);
}

.category-item.is-black {
  color: #ffffff;
  background: #000000;
}
.category-item.is-black:hover {
  background: #4B4D4E;
}

/* txt */
.txt p {
  margin-bottom: 1em;
}
.txt p:last-child {
  margin-bottom: 0;
}

/* align */
.align-right {
  float: right;
  margin: 0 0 0.7rem 0.7rem;
}
.align-left {
  float: left;
  margin: 0.7rem 0.7rem 0 0;
}
.align-center {
  display: block;
  margin: 0 auto 0.7rem;
}

/*--------------------------------------------------------------------/
/* common-support
---------------------------------------------------------------------*/
.common-support {
  color: #ffffff;
  padding-top: clamp(4em, 9vw, 9em);
  padding-bottom: clamp(5em, 9vw, 9em);
  overflow: hidden;
  position: relative;
}
.common-support__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.common-support__bg img {
  object-position: right center;
}
.common-support__inner {
  position: relative;
  z-index: 2;
  width: 100%;
}
.common-support__ttl {
  margin-bottom: clamp(2em, 4vw, 3rem);
}
.common-support__ttl em {
  font-style: normal;
  display: block;
  color: #00A0E9;
  margin-bottom: 0.2em;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .common-support__ttl em {
    font-size: 1.63rem;
  }
}
.common-support__ttl b {
  display: block;
  font-size: calc(1rem + 20 * (100vw - 280px) / 1300);
  font-weight: 900;
}
@media (min-width: 1553px) {
  .common-support__ttl b {
    font-size: 2.25rem;
  }
}

.common-support__txt__catch {
  margin-bottom: 0.5em;
  line-height: 1.5;
  font-weight: 700;
  font-size: calc(1.5rem + 30 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .common-support__txt__catch {
    font-size: 3.38rem;
  }
}
.common-support__txt__catch span {
  display: inline-block;
}
.common-support__txt .txt {
  position: relative;
  width: 100%;
  width: 60%;
  line-height: 2;
  font-weight: 700;
  font-size: calc(0.94rem + 7 * (100vw - 280px) / 1300);
}
@media (min-width: 768px) {
  .common-support__txt .txt {
    width: 75%;
  }
}
@media (min-width: 1176px) {
  .common-support__txt .txt {
    width: 57%;
  }
}
@media (min-width: 1553px) {
  .common-support__txt .txt {
    font-size: 1.37em;
  }
}
.common-support__txt .txt span {
  color: #FFF237;
}

.common-support__bnr {
  margin-top: clamp(2.5em, 4vw, 3.5em);
  display: flex;
  flex-direction: column;
  row-gap: clamp(1em, 1.5vw, 1.5em);
}
@media (min-width: 768px) {
  .common-support__bnr {
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 5.3%;
  }
}
@media (min-width: 1553px) {
  .common-support__bnr {
    width: 68.75%;
  }
}
@media (max-width: 767px) {
  .common-support__bnr {
    max-width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (min-width: 768px) {
  .common-support__bnr li {
    width: 47.35%;
  }
}
.common-support__bnr li a {
  display: block;
  box-shadow: 0 0 10px rgba(9, 25, 52, 0.1);
  transition: all 0.3s ease 0s;
}
.common-support__bnr li a:hover {
  filter: brightness(120%);
  box-shadow: 0 0 20px rgba(9, 25, 52, 0.2);
}

/*--------------------------------------------------------------------/
/* common-banner
-----------------------------------------------------------------*/
.common-banner {
  padding: clamp(3rem, 5vw, 5rem) 0;
}
@media (min-width: 1176px) {
  .common-banner {
    padding: 5rem 0;
  }
}
.common-banner__list {
  display: flex;
  flex-wrap: wrap;
}
.common-banner__list li {
  margin-bottom: 1.5em;
}
.common-banner__item a {
  display: block;
  border: 1px solid #E5E9EC;
  transition: all 0.3s ease 0s;
}
.common-banner__item a:hover {
  opacity: 0.7;
}
.common-banner__item a img {
  width: 100%;
}

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
.footer {
  position: relative;
  background: #222222;
  overflow: hidden;
}

.f-inner {
  color: #222222;
  background: #F6F6F6;
}
@media (min-width: 1176px) {
  .f-inner {
    padding-bottom: calc(env(safe-area-inset-bottom) + clamp(3rem, 4vw, 4rem));
  }
}
@media (max-width: 1175px) {
  .f-inner {
    padding-bottom: calc(env(safe-area-inset-bottom) + clamp(4rem, 6vw, 6rem));
  }
}
.f-inner .l-cont {
  position: relative;
}
.f-flex {
  display: flex;
  margin-bottom: clamp(1rem, 2vw, 2rem);
}
@media (min-width: 1176px) {
  .f-flex {
    padding-top: clamp(2rem, 4vw, 4rem);
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 5%;
  }
}
@media (max-width: 1175px) {
  .f-flex {
    padding-top: clamp(3rem, 6vw, 6rem);
    flex-direction: column-reverse;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .f-info {
    padding-right: 2%;
  }
}
@media (min-width: 1553px) {
  .f-info {
    padding-right: 5%;
  }
}
.f-info__ttl {
  margin-bottom: 1em;
  max-width: 300px;
}
@media (max-width: 767px) {
  .f-info__ttl {
    width: 60%;
  }
}
.f-info__ttl img {
  width: 100%;
}
.f-info address {
  line-height: 1.5;
  font-size: 0.94em;
}

.f-copy {
  font-size: 0.875em;
  color: #ffffff;
  padding: 1em 1.5em;
  padding-bottom: calc(env(safe-area-inset-bottom) + 1em);
  text-align: center;
  background: #00A0E9;
}

@media (max-width: 1175px) {
  .f-nav {
    display: none;
  }
}
@media (min-width: 1176px) {
  .f-nav__items {
    display: grid;
    justify-items: start;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(5, minmax(auto, max-content));
    column-gap: clamp(1em, 3vw, 3em);
  }
}
.f-nav__items > li {
  line-height: 1.5;
}
.f-nav__items > li a {
  display: block;
  color: #222222;
  position: relative;
  text-decoration: none !important;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .f-nav__items > li a {
    font-size: clamp(11px, 1.02vw, 18px);
  }
}
@media (min-width: 1553px) {
  .f-nav__items > li a {
    font-size: 1.14em;
  }
}
.f-nav__items > li a:hover {
  text-decoration: underline !important;
}
.f-nav__items > li .f-nav__items__lv2 {
  margin-top: 0.5em;
  font-size: 0.94em;
}
.f-nav__items > li .f-nav__items__lv2 li a {
  display: block;
  padding-left: 1.25em;
  font-weight: 500;
}
.f-nav__items > li .f-nav__items__lv2 li a::before {
  position: absolute;
  top: 0.75em;
  left: 0;
  content: "";
  width: 0.7em;
  height: 1px;
  background: #00A0E9;
}

.f-nav__items > .f-nav__item-1 {
  grid-row: 1/2;
  grid-column: 1;
}
.f-nav__items > .f-nav__item-2 {
  grid-row: 1/span 2;
  grid-column: 2;
}
.f-nav__items > .f-nav__item-3 {
  grid-row: 1/2;
  grid-column: 3;
}
.f-nav__items > .f-nav__item-4 {
  grid-row: 1/span 2;
  grid-column: 4;
}
.f-nav__items > .f-nav__item-5 {
  grid-row: 1/span 2;
  grid-column: 4;
  margin-top: 2em;
}
.f-nav__items > .f-nav__item-6 {
  grid-row: 1/2;
  grid-column: 5;
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
  position: absolute;
  z-index: 6;
  bottom: 4.5rem;
  right: 5%;
}
@media (min-width: 768px) {
  .pagetop {
    right: 6%;
  }
}
@media (min-width: 1553px) {
  .pagetop {
    right: 48px;
  }
}
@media (min-width: 1921px) {
  .pagetop {
    right: 5vw;
  }
}
.pagetop a {
  display: block;
  width: 48px;
  height: 48px;
  color: #ffffff;
  background: #00A0E9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none !important;
  font-size: 1.5rem;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1176px) {
  .pagetop a {
    width: 60px;
    height: 60px;
    font-size: 2.25rem;
  }
}
.pagetop a:hover {
  background: #008bf5;
}
.pagetop i {
  vertical-align: top;
}

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
/* 2column */
.l-col-2-sm {
  display: flex;
}
@media (min-width: 576px) {
  .l-col-2-sm {
    flex-wrap: wrap;
    column-gap: 5.55%;
    row-gap: 3vw;
  }
  .l-col-2-sm > * {
    flex-basis: 47.22%;
  }
}
@media (max-width: 575px) {
  .l-col-2-sm {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-2-sm > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.l-col-3-sm {
  display: flex;
}
@media (min-width: 576px) {
  .l-col-3-sm {
    flex-wrap: wrap;
    column-gap: 5.2%;
    row-gap: 3vw;
  }
  .l-col-3-sm > * {
    flex-basis: 47.4%;
  }
}
@media (min-width: 1176px) {
  .l-col-3-sm {
    column-gap: 3.7%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.86%;
  }
}
@media (min-width: 1553px) {
  .l-col-3-sm {
    column-gap: 4.16%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.55%;
  }
}
@media (max-width: 575px) {
  .l-col-3-sm {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-3-sm > * {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.l-col-3-sm2 {
  display: flex;
}
@media (min-width: 576px) {
  .l-col-3-sm2 {
    flex-wrap: wrap;
    column-gap: 5.2%;
    row-gap: 3vw;
  }
  .l-col-3-sm2 > * {
    flex-basis: 47.4%;
  }
}
@media (min-width: 1176px) {
  .l-col-3-sm2 {
    column-gap: 2.77%;
  }
  .l-col-3-sm2 > * {
    flex-basis: 31.48%;
  }
}
@media (min-width: 1553px) {
  .l-col-3-sm2 {
    column-gap: 4.16%;
  }
  .l-col-3-sm2 > * {
    flex-basis: 30.55%;
  }
}
@media (max-width: 575px) {
  .l-col-3-sm2 {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-3-sm2 > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.l-col-3-md {
  display: flex;
}
@media (min-width: 768px) {
  .l-col-3-md {
    flex-wrap: wrap;
    column-gap: 3.9%;
    row-gap: 3vw;
  }
  .l-col-3-md > * {
    flex-basis: 48.05%;
  }
}
@media (min-width: 1176px) {
  .l-col-3-md {
    column-gap: 3.7%;
  }
  .l-col-3-md > * {
    flex-basis: 30.86%;
  }
}
@media (min-width: 1553px) {
  .l-col-3-md {
    column-gap: 4.16%;
  }
  .l-col-3-md > * {
    flex-basis: 30.55%;
  }
}
@media (max-width: 767px) {
  .l-col-3-md {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-3-md > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.l-col-3-lg {
  display: flex;
}
@media (min-width: 1176px) {
  .l-col-3-lg {
    flex-wrap: wrap;
    column-gap: 3.7%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.86%;
  }
}
@media (min-width: 1553px) {
  .l-col-3-lg {
    column-gap: 4.16%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.55%;
  }
}
@media (max-width: 767px) {
  .l-col-3-lg {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-3-lg > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.l-col-4 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5.31%;
  row-gap: 5vw;
}
.l-col-4 > * {
  flex-basis: 47.34%;
}
@media (min-width: 1176px) {
  .l-col-4 {
    column-gap: 1.85%;
  }
  .l-col-4 > * {
    flex-basis: 23.61%;
  }
}
@media (min-width: 1553px) {
  .l-col-4 {
    column-gap: 2.08%;
    row-gap: 3rem;
  }
  .l-col-4 > * {
    flex-basis: 23.43%;
  }
}
.l-col-4-xs {
  display: flex;
}
@media (min-width: 376px) {
  .l-col-4-xs {
    flex-wrap: wrap;
    column-gap: 10.63%;
    row-gap: 3vw;
  }
  .l-col-4-xs > * {
    flex-basis: 44.68%;
  }
}
@media (min-width: 1176px) {
  .l-col-4-xs {
    column-gap: 1.85%;
  }
  .l-col-4-xs > * {
    flex-basis: 23.61%;
  }
}
@media (min-width: 1553px) {
  .l-col-4-xs {
    column-gap: 2.08%;
    row-gap: 2.5rem;
  }
  .l-col-4-xs > * {
    flex-basis: 23.43%;
  }
}
@media (max-width: 375px) {
  .l-col-4-xs {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-4-xs > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.l-col-4-sm {
  display: flex;
}
@media (min-width: 576px) {
  .l-col-4-sm {
    flex-wrap: wrap;
    column-gap: 6.94%;
    row-gap: 3vw;
  }
  .l-col-4-sm > * {
    flex-basis: 46.53%;
  }
}
@media (min-width: 1176px) {
  .l-col-4-sm {
    column-gap: 1.85%;
  }
  .l-col-4-sm > * {
    flex-basis: 23.61%;
  }
}
@media (min-width: 1553px) {
  .l-col-4-sm {
    column-gap: 2.08%;
    row-gap: 2.5rem;
  }
  .l-col-4-sm > * {
    flex-basis: 23.43%;
  }
}
@media (max-width: 575px) {
  .l-col-4-sm {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-4-sm > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.l-col-4-md {
  display: flex;
}
@media (min-width: 768px) {
  .l-col-4-md {
    flex-wrap: wrap;
    column-gap: 3.9%;
    row-gap: 3vw;
  }
  .l-col-4-md > * {
    flex-basis: 48.05%;
  }
}
@media (min-width: 1176px) {
  .l-col-4-md {
    column-gap: 1.85%;
  }
  .l-col-4-md > * {
    flex-basis: 23.61%;
  }
}
@media (min-width: 1553px) {
  .l-col-4-md {
    column-gap: 2.08%;
    row-gap: 2.5rem;
  }
  .l-col-4-md > * {
    flex-basis: 23.43%;
  }
}
@media (max-width: 767px) {
  .l-col-4-md {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
  .l-col-4-md > * {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/*--------------------------------------------------------------------/
	sns
/--------------------------------------------------------------------*/
.sns-list__side {
  position: fixed;
  top: 40%;
  z-index: 7;
}
@media (max-width: 767px) {
  .sns-list__side {
    display: none;
  }
}
.sns-list__side.is-left {
  left: 0.5rem;
}
.sns-list__side.is-left li {
  transform: translateX(calc(-32px - 10px));
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li {
    transform: translateX(-2.66vw);
  }
}
.sns-list__side.is-left li.is-active:nth-child(1) {
  animation: 0.8s bounceInLeft 0.3s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(1) {
    animation: 0.8s bounceInLeftFull 0.3s forwards;
  }
}
.sns-list__side.is-left li.is-active:nth-child(2) {
  animation: 0.8s bounceInLeft 0.4s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(2) {
    animation: 0.8s bounceInLeftFull 0.4s forwards;
  }
}
.sns-list__side.is-left li.is-active:nth-child(3) {
  animation: 0.8s bounceInLeft 0.5s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(3) {
    animation: 0.8s bounceInLeftFull 0.5s forwards;
  }
}
.sns-list__side.is-left li.is-active:nth-child(4) {
  animation: 0.8s bounceInLeft 0.6s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(4) {
    animation: 0.8s bounceInLeftFull 0.6s forwards;
  }
}
.sns-list__side.is-left li.is-active:nth-child(5) {
  animation: 0.8s bounceInLeft 0.7s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(5) {
    animation: 0.8s bounceInLeftFull 0.7s forwards;
  }
}
.sns-list__side.is-left li.is-active:nth-child(6) {
  animation: 0.8s bounceInLeft 0.8s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-left li.is-active:nth-child(6) {
    animation: 0.8s bounceInLeftFull 0.8s forwards;
  }
}
.sns-list__side.is-right {
  right: 0.5rem;
}
.sns-list__side.is-right li {
  transform: translateX(calc(32px + 1em));
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li {
    transform: translateX(2.66vw);
  }
}
.sns-list__side.is-right li.is-active:nth-child(1) {
  animation: 0.8s bounceInRight 0.3s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(1) {
    animation: 0.8s bounceInRightFull 0.3s forwards;
  }
}
.sns-list__side.is-right li.is-active:nth-child(2) {
  animation: 0.8s bounceInRight 0.4s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(2) {
    animation: 0.8s bounceInRightFull 0.4s forwards;
  }
}
.sns-list__side.is-right li.is-active:nth-child(3) {
  animation: 0.8s bounceInRight 0.5s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(3) {
    animation: 0.8s bounceInRightFull 0.5s forwards;
  }
}
.sns-list__side.is-right li.is-active:nth-child(4) {
  animation: 0.8s bounceInRight 0.6s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(4) {
    animation: 0.8s bounceInRightFull 0.6s forwards;
  }
}
.sns-list__side.is-right li.is-active:nth-child(5) {
  animation: 0.8s bounceInRight 0.7s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(5) {
    animation: 0.8s bounceInRightFull 0.7s forwards;
  }
}
.sns-list__side.is-right li.is-active:nth-child(6) {
  animation: 0.8s bounceInRight 0.8s forwards;
}
@media (min-width: 1921px) {
  .sns-list__side.is-right li.is-active:nth-child(6) {
    animation: 0.8s bounceInRightFull 0.8s forwards;
  }
}
.sns-list__side ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sns-list__side li {
  padding: 0.4rem 0;
  flex-shrink: 1;
}
.sns-list__side li a {
  display: block;
  position: relative;
  line-height: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #00A0E9;
  overflow: hidden;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1921px) {
  .sns-list__side li a {
    width: 1.66vw;
    height: 1.66vw;
  }
}
.sns-list__side li a::before {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 0.85em;
  text-align: center;
  vertical-align: middle;
  font-weight: 100;
}
.sns-list__side li a:hover {
  transform: scale(1.15, 1.15);
  background: #008bf5;
}
.sns-list__side li a.facebook:before {
  content: "\e912";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__side li a.facebook::before {
  font-size: 1em;
}
.sns-list__side li a.x_twitter:before {
  content: "\e90b";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__side li a.x_twitter::before {
  font-size: 0.9em;
}
.sns-list__side li a.instagram:before {
  content: "\e90d";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__side li a.Line:before {
  content: "\e90e";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__side li a.youtube:before {
  content: "\e910";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__side li a.tiktok:before {
  content: "\e917";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes bounceInLeft {
  to {
    animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
  }
  from {
    transform: translateX(calc(-32px - 1em));
  }
  40% {
    transform: translateX(16px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceInLeftFull {
  to {
    animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
  }
  from {
    transform: translateX(-2.66vw);
  }
  40% {
    transform: translateX(16px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  to {
    animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
  }
  from {
    transform: translateX(calc(32px + 1em));
  }
  40% {
    transform: translateX(-16px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceInRightFull {
  to {
    animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
  }
  from {
    transform: translateX(2.66vw);
  }
  40% {
    transform: translateX(-16px);
  }
  to {
    transform: translateX(0);
  }
}
.sns-list__sp {
  padding: 1.5rem 10% 0;
  display: flex;
  justify-content: center;
}
.sns-list__sp ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0;
}
body.open .sns-list__sp ul {
  opacity: 1;
  transition: all 0.5s ease 1.2s;
  transition: 0.5s ease 1.2s;
}
.sns-list__sp li {
  padding: 0.3rem;
  flex-shrink: 1;
}
.sns-list__sp li a {
  display: block;
  position: relative;
  line-height: 1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #00A0E9;
  overflow: hidden;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.sns-list__sp li a::before {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.sns-list__sp li a:hover {
  background: #008bf5;
}
.sns-list__sp li a.facebook:before {
  content: "\e912";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__sp li a.facebook::before {
  font-size: 1.4em;
}
.sns-list__sp li a.x_twitter:before {
  content: "\e90b";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__sp li a.x_twitter::before {
  font-size: 1.2em;
}
.sns-list__sp li a.instagram:before {
  content: "\e90d";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__sp li a.Line:before {
  content: "\e90e";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__sp li a.youtube:before {
  content: "\e910";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__sp li a.tiktok:before {
  content: "\e917";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sns-list__footer {
  display: flex;
}
@media (min-width: 768px) {
  .sns-list__footer {
    column-gap: 1.5em;
    flex-shrink: 0;
  }
}
@media (max-width: 767px) {
  .sns-list__footer {
    margin-top: 1rem;
    flex-direction: column;
  }
}
.sns-list__footer a {
  color: #6F757B;
  padding-left: 2rem;
  position: relative;
  font-size: 1.25rem;
  text-decoration: none !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767px) {
  .sns-list__footer a {
    font-size: 1.38rem;
    padding-left: 2.5rem;
  }
}
.sns-list__footer a:hover {
  color: #979DA4;
}
.sns-list__footer a::before {
  position: absolute;
  left: 0;
  top: 0.4rem;
  font-weight: 100;
  font-size: 0.875em;
}
@media (max-width: 767px) {
  .sns-list__footer a::before {
    top: 0.4rem;
  }
}
.sns-list__footer a.facebook:before {
  content: "\e90a";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__footer a.x_twitter:before {
  content: "\e90b";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__footer a.instagram:before {
  content: "\e90d";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__footer a.Line:before {
  content: "\e90e";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__footer a.youtube:before {
  content: "\e910";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sns-list__footer a.tiktok:before {
  content: "\e917";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
/*-- next-prev --*/
.next-prev {
  margin-top: clamp(2rem, 3vw, 3rem);
  color: #979DA4;
  display: grid;
  align-items: center;
  max-width: 960px;
  font-weight: 500;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 576px) {
  .next-prev {
    column-gap: 2%;
    grid-template-columns: 23% 50% 23%;
  }
}
@media (min-width: 1921px) {
  .next-prev {
    max-width: 50vw;
  }
}
@media (max-width: 575px) {
  .next-prev {
    grid-template-columns: 45% 45%;
    grid-template-rows: auto 1fr;
    column-gap: 10%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
.next-prev__next {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 575px) {
  .next-prev__next {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
.next-prev__next a {
  vertical-align: middle;
  position: relative;
  padding-left: 2em;
  font-size: 1.07em;
  color: #222222;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  line-height: 1;
}
.next-prev__next a:before {
  content: "\e314";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .next-prev__next a {
    font-size: 1.13rem;
  }
}
.next-prev__next a::before {
  position: absolute;
  top: 55%;
  left: 0.2em;
  line-height: 1;
  transform: translateY(-50%);
  color: #6F757B;
  transition: all 0.3s ease 0s;
  font-size: 1.5em;
}
.next-prev__next a:hover {
  color: #008bf5;
}
.next-prev__next a:hover::before {
  color: #008bf5;
}
@media (max-width: 575px) {
  .next-prev__center {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}
.next-prev__center a {
  max-width: 360px;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 1921px) {
  .next-prev__center a {
    max-width: 18.75vw;
  }
}
.next-prev__prev {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 575px) {
  .next-prev__prev {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
.next-prev__prev a {
  vertical-align: middle;
  position: relative;
  padding-right: 2em;
  font-weight: 500;
  color: #222222;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  line-height: 1;
  font-size: 1.07em;
}
.next-prev__prev a:after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 768px) {
  .next-prev__prev a {
    font-size: 1.13rem;
  }
}
.next-prev__prev a::after {
  position: absolute;
  top: 55%;
  right: 0.2em;
  line-height: 1;
  transform: translateY(-50%);
  color: #6F757B;
  transition: all 0.3s ease 0s;
  font-size: 1.5em;
}
.next-prev__prev a:hover {
  color: #008bf5;
}
.next-prev__prev a:hover::after {
  color: #008bf5;
}

.pagination {
  text-align: center;
  color: #979DA4;
  margin-top: clamp(2rem, 4vw, 4rem);
  row-gap: 1em;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.pagination__list {
  column-gap: 4px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.pagination__list > * {
  margin-bottom: 0.5rem !important;
}
.pagination__list::after::after {
  display: block;
  content: "";
  clear: both;
}
.pagination__list a {
  color: #979DA4;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  border: 1px solid #C9CED2;
}
.pagination__list a:hover {
  border-color: #008bf5;
  background: #008bf5;
  color: #ffffff;
}
.pagination__list .prev, .pagination__list .next {
  background: #C9CED2;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination__list .prev i, .pagination__list .next i {
  font-size: 1.25em;
  display: block;
  line-height: 1;
}
.pagination__select {
  position: relative;
  width: 140px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.pagination__select:after {
  content: "\e313";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination__select::after {
  position: absolute;
  top: 0.4em;
  right: 0.2em;
  color: #979DA4;
  font-size: 1.5em;
  pointer-events: none;
}
.pagination__select .page-select {
  width: 100%;
  padding: 0.5em 1em;
  font-size: 1em;
  color: #4B4D4E;
  border: 1px solid #C9CED2;
  border-radius: 4px;
}

.page-numbers {
  padding: 0;
  line-height: 1;
  transition: 0.2 0.3s ease 0s;
  font-size: 0.88rem;
  letter-spacing: 0;
  color: #ffffff;
  vertical-align: top;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  display: block;
  line-height: 34px;
}
.page-numbers.current {
  border: 1px solid #00A0E9;
  color: #00A0E9;
}
.page-numbers.dots {
  color: #979DA4;
}

.nav-prev {
  float: left;
  margin-top: 24px;
  margin-top: 1.5rem;
}

.nav-next {
  float: right;
  margin-top: 24px;
  margin-top: 1.5rem;
}

/*-------------------------------------------------
/* single-nextprev
-------------------------------------------------*/
.single-nextprev {
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
}
.single-nextprev::after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 2em);
  left: 50%;
  top: 1em;
  background: #E5E9EC;
}
.single-nextprev a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  color: #222222;
  position: relative;
  text-decoration: none !important;
  padding: 1em 0;
  transition: all 0.3s ease 0s;
  border-top: 1px solid #E5E9EC;
  border-bottom: 1px solid #E5E9EC;
}
@media (max-width: 575px) {
  .single-nextprev a {
    flex-direction: column-reverse;
    align-content: flex-end;
  }
}

.single-nextprev__img {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin: 0;
}
@media (min-width: 576px) {
  .single-nextprev__img {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .single-nextprev__img {
    width: 80px;
    height: 80px;
  }
}
@media (max-width: 575px) {
  .single-nextprev__img {
    width: 100%;
    height: auto;
  }
}
.single-nextprev__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.single-nextprev__img__inner::before {
  content: "";
  display: block;
  padding-top: 50%;
  position: relative;
}
.single-nextprev__img__inner > div,
.single-nextprev__img__inner figure,
.single-nextprev__img__inner a,
.single-nextprev__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 576px) {
  .single-nextprev__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .single-nextprev__img__inner::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
  }
  .single-nextprev__img__inner > div,
  .single-nextprev__img__inner figure,
  .single-nextprev__img__inner a,
  .single-nextprev__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.single-nextprev__txt {
  flex-shrink: 1;
  padding: 0.5em 0 1.75rem;
  width: 100%;
  position: relative;
}
@media (min-width: 576px) {
  .single-nextprev__txt {
    width: calc(100% - 50px);
  }
}
@media (min-width: 768px) {
  .single-nextprev__txt {
    width: calc(100% - 80px);
  }
}
.single-nextprev__tit {
  font-weight: 500;
  font-size: calc(0.8rem + 4 * (100vw - 280px) / 1300);
}
@media (min-width: 576px) {
  .single-nextprev__tit {
    font-size: calc(0.7rem + 2 * (100vw - 576px) / 1004);
  }
}
@media (min-width: 1553px) {
  .single-nextprev__tit {
    font-size: 0.94rem;
  }
}
.single-nextprev__time {
  font-family: 'Poppins', sans-serif;
  font-size: 0.88rem;
  color: #979DA4;
}
.single-nextprev__category {
  font-weight: 500;
  color: #979DA4;
  font-size: calc(0.8rem + 2 * (100vw - 280px) / 1300);
}
@media (min-width: 1553px) {
  .single-nextprev__category {
    font-size: 0.94rem;
  }
}
.single-nextprev__arrow {
  font-size: 0.81rem;
}
.single-nextprev .next_post {
  position: relative;
  grid-column: 1;
}
.single-nextprev .next_post a {
  padding-right: 1em;
}
@media (min-width: 576px) {
  .single-nextprev .next_post a {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}
.single-nextprev .next_post a:hover .single-nextprev__arrow {
  color: #00A0E9;
  margin-left: -5px;
}
@media (min-width: 576px) {
  .single-nextprev .next_post .single-nextprev__txt {
    padding-left: 0.75em;
  }
}
.single-nextprev .next_post .single-nextprev__arrow {
  position: absolute;
  left: 0.25em;
  bottom: 0;
  vertical-align: middle;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
.single-nextprev .next_post .single-nextprev__arrow:before {
  content: "\e314";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-nextprev .next_post .single-nextprev__arrow::before {
  font-size: 1.25rem;
}
.single-nextprev .prev_post {
  position: relative;
  grid-column: 2;
}
.single-nextprev .prev_post a {
  justify-content: flex-end;
  padding-left: 1em;
}
.single-nextprev .prev_post a:hover .single-nextprev__arrow {
  color: #00A0E9;
  margin-right: -5px;
}
.single-nextprev .prev_post .single-nextprev__txt {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 576px) {
  .single-nextprev .prev_post .single-nextprev__txt {
    padding-right: 0.75em;
  }
}
.single-nextprev .prev_post .single-nextprev__category {
  display: flex;
  justify-content: flex-end;
}
.single-nextprev .prev_post .single-nextprev__arrow {
  position: absolute;
  right: 0.25em;
  bottom: 0;
  vertical-align: middle;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}
.single-nextprev .prev_post .single-nextprev__arrow:after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-nextprev .prev_post .single-nextprev__arrow::after {
  font-size: 1.25rem;
}

/* Preload images */
body:after {
  content: url(../img/common/close.png) url(../img/common/loading.gif) url(../img/common/prev.png) url(../img/common/next.png);
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/common/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/common/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/common/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
  padding-top: 0.5rem;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/common/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* print css
----------------------------------------------------------------*/
@media print {
  header {
    display: none;
  }
  nav#global {
    display: none;
  }
  footer {
    display: none;
  }
}