@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
/* common-support
---------------------------------------------------------------------*/
.common-support {
	color: $white;
	padding-top: clamp(4em, 9vw, 9em);
	padding-bottom: clamp(5em, 9vw, 9em);
	overflow: hidden;
	position: relative;
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			object-position: right center;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		width: 100%;
	}
	&__ttl {
		margin-bottom: clamp(2em, 4vw, 3rem);
		em {
			font-style: normal;
			display: block;
			color: $main_c;
			margin-bottom: 0.2em;
			@include f-family(font02);
			@include f-w(600);
			@include line-h(1);
			@include l-sp(0);
			@include f-s_xxs(1, 8);
			@include media-breakpoint-up(xl) {
				@include f-size(26);
			}
		}
		b {
			display: block;
			@include f-s_xxs(1, 20);
			@include f-w(900);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
}

.common-support__txt {
	&__catch {
		margin-bottom: 0.5em;
		@include line-h(1.5);
		@include f-w(700);
		@include f-s_xxs(1.5, 30);
		@include media-breakpoint-up(xl) {
			@include f-size(54);
		}
		span {
			display: inline-block;
		}
	}
	.txt {
		position: relative;
		width: 100%;
		width: 60%;
		@include line-h(2);
		@include f-w(700);
		@include f-s_xxs(0.94, 7);
		@include media-breakpoint-up(md) {
			width: 75%;
		}
		@include media-breakpoint-up(lg) {
			width: 57%;
		}
		@include media-breakpoint-up(xl) {
			font-size: 1.37em;
		}
		span {
			color: $point_c;
		}
	}
}

.common-support__bnr {
	margin-top: clamp(2.5em, 4vw, 3.5em);
	display: flex;
	flex-direction: column;
	row-gap: clamp(1em,1.5vw,1.5em);
	@include media-breakpoint-up(md) {
		flex-wrap: wrap;
		flex-direction: row;
		column-gap: 5.3%;
	}
	@include media-breakpoint-up(xl) {
		width: 68.75%;
	}
	@include media-breakpoint-down(sm) {
		max-width: 480px;
		@include m-a;
	}
	& li {
		@include media-breakpoint-up(md) {
			width: 47.35%;
		}
		a {
			display: block;
			box-shadow: 0 0 10px rgba($b-gray,0.1);
			@include transition;
			&:hover {
				filter: brightness(120%);
				box-shadow: 0 0 20px rgba($b-gray,0.2);
			}
		}
	}
}
