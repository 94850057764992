@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
	component(common)
----------------------------------------------------------------*/

/*	heading
/--------------------------------------------------------------------*/

.cont-ttl {
	b {
		position: relative;
		display: block;
		@include f-s_xxs(1.5,20);
		@include line-h(1.2);
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			@include f-s_md(1.5,20);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
		&::before {
			position: absolute;
			top: 50%;
			right: calc(100% + 0.5em);
			content: "";
			width: 100vw;
			height: 2px;
			background: $main_c;
		}
	}
	em {
		display: block;
		font-style: normal;
		color: $main_c;
		margin-top: 0.5em;
		@include line-h(1.2);
		@include media-breakpoint-up(lg) {
			@include f-s_md(0.9,8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

// heading-ttl
.heading-ttl {
	line-height: 1.4;
	padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
	margin-bottom: calc(2rem + 0.5em);
	position: relative;
	text-align: center;
	@include f-s_xs(1.25, 12);
	@include f-w(700);
	@include media-breakpoint-up(xl) {
		@include f-size(32);
	}
	&::before {
		content: "";
		position: absolute;
		width: 4rem;
		height: 3px;
		bottom: 0;
		left: calc(50% - 2rem);
		border-radius: 2px;
		background: $main_c;
	}
}

.heading-ttl2 {
	line-height: 1.4;
	padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
	margin-bottom: calc(2rem + 0.5em);
	position: relative;
	text-align: center;
	@include f-s_xs(1.14, 8);
	@include f-w(700);
	@include media-breakpoint-up(xl) {
		@include f-size(26);
	}
	&::before {
		content: "";
		position: absolute;
		width: 4rem;
		height: 3px;
		bottom: 0;
		left: calc(50% - 2rem);
		border-radius: 2px;
		background: $main_c;
	}
}

/* teble用のタイトル */
.table-ttl {
	padding: 1em 1.5em;
	background: $p-gray;
	@include line-h(1.5);
}

/* ol
/--------------------------------------------------------------------*/
.common-ol {
	margin-left: 5px;
	counter-reset: ol-num;
	& > li {
		line-height: 1.4;
		padding-left: 1.5em;
		text-indent: -1.5em;
		position: relative;
		&::before {
			counter-increment: ol-num;
			content: counter(ol-num) ".";
			padding-right: 0.5em;
			text-align: center;
		}
		&:not(:last-child) {
			margin-bottom: 0.7rem;
		}
	}
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
	text-align: left;
	row-gap: 0.5em;
	@include flex-column;
	&.is-main {
		li::before {
			color: $main_c;
		}
	}
	& li {
		line-height: 1.5;
		padding-left: 1.5rem;
		position: relative;
		&::before {
			content: "●";
			color: $main_c;
			position: absolute;
			left: 0;
			top: 0.35em;
			font-size: 0.7em;
		}
	}
}

.common-ul2 {
	margin-left: 5px;
	text-align: left;
	row-gap: 0.5em;
	@include flex-column;
	& li {
		line-height: 1.5;
		padding-left: 1rem;
		position: relative;
		&::before {
			content: "・";
			position: absolute;
			color: $txt_c;
			left: 0;
			top: 0;
		}
	}
}

.caution-ul {
	margin-left: 5px;
	row-gap: 0.5em;
	@include flex-column;
	& li {
		line-height: 1.5;
		padding-left: 1.2rem;
		position: relative;
		&::before {
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
	margin-left: 5px;
	margin-bottom: 2rem;
	& dt {
		line-height: 1.4;
		&:before {
			content: "●";
			padding-right: 0.3em;
		}
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}

.common-dl2 {
	margin-bottom: 1rem;
	& dt {
		line-height: 1.4;
		padding-bottom: 0.7rem;
		border-bottom: 1px solid $m-gray;
		margin-bottom: 0.7rem;
		font-size: 1em;
		@include f-w(500);
		font-size: 1.14em;
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

/* table
----------------------------------------------------------------*/
.common-table {
	width: 100%;
	font-size: 0.9375em;
	& tr {
		border-bottom: 1px solid $m-gray;
		&:first-child {
			border-top: 1px solid $m-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		padding: 1em 0;
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			display: table-cell;
			width: 25%;
			min-width: 180px;
			padding: 1.25em 0;
		}
		@include media-breakpoint-up(xl) {
			width: 240px;
		}
		@include media-breakpoint-down(md) {
			display: block;
			width: 100%;
			padding-bottom: 0;
		}
	}
	& td.column-2 {
		vertical-align: center;
		@include media-breakpoint-up(md) {
			display: table-cell;
			padding: 1em 1.25em 1em 0.5em;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			width: 100%;
			padding-top: 0;
			padding-left: 0;
			padding-bottom: 1em;
		}
	}
}

.common-table2 {
	width: 100%;
	font-size: 0.9375em;
	& tr {
		border-bottom: 1px solid $m-gray;
		&:first-child {
			border-top: 1px solid $m-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		padding: 1em 1.25em;
		background: $p-gray;
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			padding: 1em 1.25em;
			width: 25%;
			min-width: 180px;
		}
		@include media-breakpoint-up(xl) {
			width: 240px;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	& td {
		padding: 1em 1.25em;
		@include media-breakpoint-up(lg) {
			padding: 1em 1.25em;
		}
		@include media-breakpoint-down(md) {
			display: block;
		}
		
	}
}

.president-table {
	width: 100%;
	@include line-h(1.5);
	@include media-breakpoint-up(xl) {
		font-size: 1.14em;
	}
	th {
		display: block;
		padding: 0.25em 0;
	}
	td {
		display: block;
		&.column-1 {
			position: relative;
			padding-left: 1em;
			border-left: 3px solid $sub_c;
			@include f-w(700);
		}
		&.column-2 {
			padding-top: 1em;
			padding-bottom: 1.5em;
		}
	}
}


/* table press CSS */
.tablepress td,
.tablepress th {
	padding: none;
}
.tablepress tbody td,
.tablepress tfoot th {
	border-top: none !important;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
// 4列並びの場合のカテゴリー

.common-category {
	&__ttl {
		@include media-breakpoint-up(lg) {
			display: none;
		}
		@include media-breakpoint-down(md) {
			padding: 0.3rem 0 0.5rem;
			margin-bottom: 0.5rem;
			border-bottom: 1px solid $m-gray;
			font-size: 1.11em;
			@include f-w(500);
		}
	}
	&__list {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			gap: 0.75em 0.75em;
			align-items: flex-end;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
		li {
			@include media-breakpoint-up(lg) {
				width: calc(20% - 0.6em);
				flex-shrink: 1;
			}
			@include media-breakpoint-down(md) {
				border-bottom: 1px solid $l-gray;
			}
			a {
				color: $txt_c;
				display: flex;
				column-gap: 0.5em;
				position: relative;
				text-align: center;
				display: block;
				@include line-h(1.2);
				@include f-w(700);
				@include dec-none;
				@include transition;
				@include media-breakpoint-up(lg) {
					padding: 0.5em 0.2em 0.8em;
					border-bottom: 0.3em solid $m-gray;
				}
				@include media-breakpoint-down(md) {
					padding: 0.75em 0.5em 0.75em 0.2em;
				}
				&::before {
					@include media-breakpoint-down(md) {
						content: "";
						position: absolute;
						left: 0;
						bottom: -1px;
						width: 0;
						height: 1px;
						background: $main_c;
						@include transition;
					}
				}
				&:hover {
					@include media-breakpoint-up(lg) {
						border-color: $hover_c;
						color: $hover_c;
					}
					&::before {
						@include media-breakpoint-down(md) {
							width: 100%;
						}
					}
				}
				.is-text {
					@include f-w(700);
				}
			}
		}
	}
}

ul.select-list {
	&.is-active {
		position: relative;
		top: -1px;
		width: 100%;
		background: $white;
		border: 1px solid $m-gray;
		padding: 0.75em 1em 1.25em;
		z-index: z-index(module, part02);
	}
}

// select-box
.select-display {
	margin-top: 1rem;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid $m-gray;
	padding: 1em;
	position: relative;
	background: $white;
	&__btn {
		display: block;
		position: absolute;
		top: calc(50% - 16px);
		right: 1rem;
		width: 32px;
		height: 32px;
		@include transition;
		i {
			color: $gray;
			@include f-size(32);
			@include line-h(1);
		}
	}
	&.is-active {
		.select-display__btn {
			transform: rotate(180deg);
		}
	}
	.is-text {
		display: block;
		width: 85%;
	}
}

/* cotegory */
.category {
	& .category-item {
		@include f-size(13);
		padding: 0.3rem 1rem;
		min-width: 140px;
		vertical-align: top;
		margin-right: 0.3rem;
		margin-bottom: 0.4rem;
	}
}

[class*="category-item"] {
	display: inline-block;
	background-color: $p-gray;
	color: $main_c;
	text-align: center;
	line-height: 1.4;
	padding: 0.4em 1.25em;
	font-size: 0.85em;
	@include l-sp(0.05em);
	@include radius(999px);
	@include f-w(600);
	@at-root a#{&} {
		@include dec-none;
		@include transition;
		&:hover {
			filter: saturate(200%);
		}
	}
}

.category-item {
	&.is-black {
		color: $white;
		background: $black;
		&:hover {
			background: $d-gray;
		}
	}
}

/* txt */
.txt {
	& p {
		margin-bottom: 1em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.is-wysiwyg {
	ul {
		@extend .common-ul;
	}
}

/* align */
.align {
	&-right {
		float: right;
		margin: 0 0 0.7rem 0.7rem;
	}
	&-left {
		float: left;
		margin: 0.7rem 0.7rem 0 0;
	}
	&-center {
		display: block;
		margin: 0 auto 0.7rem;
	}
}
