@use "../_variables/variables" as *;
@use "break-points" as *;

//	utility
//---------------------------------------------------

// clearfix
@mixin clearfix() {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}

// scroll-bar
@mixin scroll-bar {
	scrollbar-width: thin;
	scrollbar-color: $m-gray $white;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: $p-gray;
	}
	&::-webkit-scrollbar-thumb {
		background: $m-gray;
		border-radius: 8px;
		box-shadow: inset 0 0 0 1px $white;
	}
}

//	filter
//---------------------------------------------------
@mixin bg_filter($color: $black, $alpha: 0.7, $position: before) {
	@if $position == both {
		$position: ":before,:after";
	}
	&:#{$position} {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		background: rgba($color, $alpha);
		width: 100%;
		height: 100%;
		z-index: z-index(module, part01);
	}
}

//	flex
//---------------------------------------------------

@mixin flex-between {
	display: flex;
	justify-content: space-between;
}

@mixin flex-around {
	display: flex;
	justify-content: space-around;
}

@mixin flex-end {
	display: flex;
	justify-content: flex-end;
}

@mixin flex-column {
	display: flex;
	flex-direction: column;
}
@mixin flex-r-reverse {
	display: flex;
	flex-direction: row-reverse;
}
@mixin flex-c-reverse {
	display: flex;
	flex-direction: column-reverse;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

//	contents
//---------------------------------------------------
@mixin cont-pad($pt: 3, $pb: 4) {
	padding-top: #{$pt}rem;
	padding-bottom: #{$pb}rem;
	@include media-breakpoint-up(lg) {
		padding-top: (#{$pt} * 1.5) rem;
		padding-bottom: (#{$pb} * 1.5) rem;
	}
}
@mixin rem-pad($top: 0, $right: 0, $bottom: 0, $left: 0) {
	padding: (($top / 16) * 1rem) (($right / 16) * 1rem) (($bottom / 16) * 1rem) (($left / 16) * 1rem);
}
@mixin rem-mar($top: 0, $right: 0, $bottom: 0, $left: 0) {
	margin: (($top / 16) * 1rem) (($right / 16) * 1rem) (($bottom / 16) * 1rem) (($left / 16) * 1rem);
}

//	dafault_parts
//---------------------------------------------------
@mixin m-a {
	margin-left: auto !important;
	margin-right: auto !important;
}

@mixin dec-line {
	text-decoration: underline !important;
}
@mixin dec-none {
	text-decoration: none !important;
}

//	border-radius
//---------------------------------------------------
@mixin radius($radius: 5px) {
	border-radius: $radius;
}
