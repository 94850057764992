@use "../_mixins/break-points" as *;
@use "../base" as *;

/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */
#wrapper {
	background: $white;
}

/* main */
.main {
	@include clearfix;
	background: $white;
	img {
		display: block;
	}
}

/* contents */
.l-cont_lg {
	max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, lg) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}

.l-cont {
	max-width: map-get($container-max-widths, xl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}

.l-cont_xxl {
	max-width: map-get($container-max-widths, xxl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, xxl) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}

.l-cont_xxxl {
	max-width: map-get($container-max-widths, xxxl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
		max-width: floor-decimal(calc(map-get($grid-num, xxxl) / map-get($grid-num, full)), 4) * 100vw +
			map-get($contmargin, full) * 2; //メインコンテンツサイズ
	}
}

.l-cont_full {
	max-width: 100%; //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		padding-left: map-get($contmargin, tb);
		padding-right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, full);
		padding-right: map-get($contmargin, full);
	}
}

/* contents-box */
// Layout box
.l-box {
	width: 100%;
	z-index: 1;
	position: relative;
	&:not(:last-of-type) {
		margin-bottom: 8rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10rem;
		}
	}
}

// 一覧詳細ページ　調整
.common-flex__list {
	&.is-col2 {
		.c-box1 {
			&__date {
				@include media-breakpoint-up(lg) {
					font-size: 1.25em;
				}
			}
			&__category {
				@include media-breakpoint-up(lg) {
					font-size: 1.25em;
				}
			}
			&__ttl {
				@include media-breakpoint-up(lg) {
					@include f-size(22);
				}
			}
		}
	}
}

// voice-box
.voice-box {
	a {
		position: relative;
		color: $txt_c;
		height: 100%;
		display: block;
		background: $white;
		padding-bottom: 3rem;
		@include dec-none;
		@include transition;
		&:hover {
			i {
				background: $hover_c;
			}
		}
	}
	&__img {
		overflow: hidden;
		display: block;
		margin-bottom: 0.75em;
		width: 100%;
		aspect-ratio: 5 / 3;
	}
	&__ttl {
		font-size: 1.14em;
		margin-bottom: 0.5em;
		@include f-w(700);
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			font-size: 0.85em;
		}
	}
	&__btn {
		position: absolute;
		right: 0;
		bottom: 0;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		column-gap: 0.5em;
		align-items: center;
		&::before {
			content: "";
			justify-self: start;
		}
		i {
			position: relative;
			width: 32px;
			height: 32px;
			content: "";
			background: $main_c;
			justify-self: end;
			@include transition;
			@include radius(50%);
			&::before {
				color: $white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include transition;
			}
		}
	}
}
