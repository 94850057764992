@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
/*-- next-prev --*/
.next-prev {
	margin-top: clamp(2rem, 3vw, 3rem);
	color: $gray;
	display: grid;
	align-items: center;
	max-width: 960px;
	@include f-w(500);
	@include m-a;
	@include media-breakpoint-up(sm) {
		column-gap: 2%;
		grid-template-columns: 23% 50% 23%;
	}
	@include media-breakpoint-up(full) {
		max-width: 50vw;
	}
	@include media-breakpoint-down(xs) {
		grid-template-columns: 45% 45%;
		grid-template-rows: auto 1fr;
		column-gap: 10%;
		row-gap: clamp(2rem, 3vw, 3rem);
	}
	&__next {
		display: flex;
		justify-content: flex-start;
		@include media-breakpoint-down(xs) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		a {
			vertical-align: middle;
			position: relative;
			padding-left: 2em;
			font-size: 1.07em;
			color: $txt_c;
			@include f-w(500);
			@include transition;
			@include dec-none;
			@include line-h(1);
			@include icon(arrow1_left);
			@include media-breakpoint-up(md) {
				@include f-size(18);
			}
			&::before {
				position: absolute;
				top: 55%;
				left: 0.2em;
				@include line-h(1);
				transform: translateY(-50%);
				color: $dl-gray;
				@include transition;
				font-size: 1.5em;
			}
			&:hover {
				color: $hover_c;
				&::before {
					color: $hover_c;
				}
			}
		}
	}
	&__center {
		@include media-breakpoint-down(xs) {
			grid-column: 1 / 3;
			grid-row: 2 / 3;
		}
		a {
			max-width: 360px;
			@include m-a;
			@include media-breakpoint-up(full) {
				max-width: floor-decimal(calc(360 / 1920), 4) * 100vw;
			}
		}
	}
	&__prev {
		display: flex;
		justify-content: flex-end;
		@include media-breakpoint-down(xs) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		a {
			vertical-align: middle;
			position: relative;
			padding-right: 2em;
			@include f-w(500);
			color: $txt_c;
			@include transition;
			@include dec-none;
			@include line-h(1);
			@include icon(arrow1_right, after);
			font-size: 1.07em;
			@include media-breakpoint-up(md) {
				@include f-size(18);
			}
			&::after {
				position: absolute;
				top: 55%;
				right: 0.2em;
				@include line-h(1);
				transform: translateY(-50%);
				color: $dl-gray;
				@include transition;
				font-size: 1.5em;
			}
			&:hover {
				color: $hover_c;
				&::after {
					color: $hover_c;
				}
			}
		}
	}
}

// pagination
.pagination {
	text-align: center;
	color: $gray;
	margin-top: clamp(2rem, 4vw, 4rem);
	row-gap: 1em;
	@include flex-column;
	@include f-w(500);
	&__list {
		column-gap: 4px;
		justify-content: center;
		@include flex-wrap;
		& > * {
			margin-bottom: 0.5rem !important;
		}
		&::after {
			@include clearfix;
		}
		a {
			color: $gray;
			text-decoration: none;
			@include transition;
			border: 1px solid $m-gray;
			&:hover {
				border-color: $hover_c;
				background: $hover_c;
				color: $white;
			}
		}
		& .prev,
		& .next {
			background: $m-gray;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				font-size: 1.25em;
				display: block;
				line-height: 1;
			}
		}
	}
	&__select {
		position: relative;
		width: 140px;
		@include m-a;
		@include icon(arrow1_bottom, after);
		&::after {
			position: absolute;
			top: 0.4em;
			right: 0.2em;
			color: $gray;
			font-size: 1.5em;
			pointer-events:none;
		}
		.page-select {
			width: 100%;
			padding: 0.5em 1em;
			font-size: 1em;
			
			color: $d-gray;
			border: 1px solid $m-gray;
			border-radius: 4px;
		}
	}
}


.page-numbers {
	padding: 0;
	@include line-h(1);
	@include transition(0.2);
	@include f-size(14);
	@include l-sp(0);
	color: $white;
	vertical-align: top;
	min-width: 36px;
	min-height: 36px;
	border-radius: 50%;
	display: block;
	line-height: 34px;
	&.current {
		border: 1px solid $main_c;
		color: $main_c;
	}
	&.dots {
		color: $gray;
	}
}

.nav-prev {
	float: left;
	margin-top: 24px;
	margin-top: 1.5rem;
}

.nav-next {
	float: right;
	margin-top: 24px;
	margin-top: 1.5rem;
}

/*-------------------------------------------------
/* single-nextprev
-------------------------------------------------*/
.single-nextprev {
	position: relative;
	display: grid;
	grid-template-columns: 50% 50%;
	&::after {
		content: "";
		position: absolute;
		width: 1px;
		height: calc(100% - 2em);
		left: 50%;
		top: 1em;
		background: $l-gray;
	}
	a {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		color: $txt_c;
		position: relative;
		@include dec-none;
		padding: 1em 0;
		@include transition;
		border-top: 1px solid $l-gray;
		border-bottom: 1px solid $l-gray;
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
			align-content: flex-end;
		}
	}
}

.single-nextprev {
	&__img {
		width: 70px;
		height: 70px;
		flex-shrink: 0;
		margin: 0;
		@include media-breakpoint-up(sm) {
			width: 50px;
			height: 50px;
		}
		@include media-breakpoint-up(md) {
			width: 80px;
			height: 80px;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			height: auto;
		}
		&__inner {
			@include aspect-img(2, 1);
			@include media-breakpoint-up(sm) {
				@include aspect-img(1, 1);
			}
		}
	}
	&__txt {
		flex-shrink: 1;
		padding: 0.5em 0 1.75rem;
		width: 100%;
		position: relative;
		@include media-breakpoint-up(sm) {
			width: calc(100% - 50px);
		}
		@include media-breakpoint-up(md) {
			width: calc(100% - 80px);
		}
	}
	&__tit {
		@include f-w(500);
		@include f-s_xxs(0.8, 4);
		@include media-breakpoint-up(sm) {
			@include f-s_sm(0.7, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(15);
		}
	}
	&__time {
		@include f-family(font02);
		@include f-size(14);
		color: $gray;
	}
	&__category {
		@include f-w(500);
		color: $gray;
		@include f-s_xxs(0.8, 2);
		@include media-breakpoint-up(xl) {
			@include f-size(15);
		}
	}
	&__arrow {
		@include f-size(13);
	}
	.next_post {
		position: relative;
		grid-column: 1;
		a {
			padding-right: 1em;
			@include media-breakpoint-up(sm) {
				justify-content: flex-end;
				flex-direction: row-reverse;
			}
			&:hover {
				.single-nextprev__arrow {
					color: $main_c;
					margin-left: -5px;
				}
			}
		}
		.single-nextprev__txt {
			@include media-breakpoint-up(sm) {
				padding-left: .75em;
			}
		}
		.single-nextprev__arrow {
			position: absolute;
			left: .25em;
			bottom: 0;
			vertical-align: middle;
			@include f-w(500);
			@include icon(arrow1_left);
			@include transition;
			&::before {
				@include f-size(20);
			}
		}
	}
	.prev_post {
		position: relative;
		grid-column: 2;
		a {
			justify-content: flex-end;
			padding-left: 1em;
			&:hover {
				.single-nextprev__arrow {
					color: $main_c;
					margin-right: -5px;
				}
			}
		}
		.single-nextprev__txt {
			display: flex;
			justify-content: flex-end;
			@include media-breakpoint-up(sm) {
				padding-right: .75em;
			}
		}
		.single-nextprev__category {
			display: flex;
			justify-content: flex-end;
		}
		.single-nextprev__arrow {
			position: absolute;
			right: .25em;
			bottom: 0;
			vertical-align: middle;
			@include f-w(500);
			@include icon(arrow1_right, after);
			@include transition;
			&::after {
				@include f-size(20);
			}
		}
	}
}