@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
.footer {
	position: relative;
	background: $txt_c;
	overflow: hidden;
}
.f- {
	&inner {
		color: $txt_c;
		background: $p-gray;
		@include media-breakpoint-up(lg) {
			padding-bottom: calc(env(safe-area-inset-bottom) + clamp(3rem, 4vw, 4rem));
		}
		@include media-breakpoint-down(md) {
			padding-bottom: calc(env(safe-area-inset-bottom) + clamp(4rem, 6vw, 6rem));
		}
		.l-cont {
			position: relative;
		}
	}
	&flex {
		display: flex;
		margin-bottom: clamp(1rem, 2vw, 2rem);
		@include media-breakpoint-up(lg) {
			padding-top: clamp(2rem, 4vw, 4rem);
			flex-direction: row-reverse;
			justify-content: flex-end;
			column-gap: 5%;
		}
		@include media-breakpoint-down(md) {
			padding-top: clamp(3rem, 6vw, 6rem);
			flex-direction: column-reverse;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&info {
		@include media-breakpoint-up(lg) {
			padding-right: 2%;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 5%;
		}
		&__ttl {
			margin-bottom: 1em;
			max-width: 300px;
			@include media-breakpoint-down(sm) {
				width: 60%;
			}
			img {
				width: 100%;
			}
		}
		address {
			line-height: 1.5;
			font-size: 0.94em;
		}
	}
}

.f-copy {
	font-size: 0.875em;
	color: $white;
	padding: 1em 1.5em;
	padding-bottom: calc(env(safe-area-inset-bottom) + 1em);
	text-align: center;
	background: $main_c;
}

.f-nav {
	@include media-breakpoint-down(md) {
		display: none;
	}
	&__items {
		@include media-breakpoint-up(lg) {
			display: grid;
			justify-items: start;
			grid-template-rows: auto 1fr;
			grid-template-columns: repeat(5, minmax(auto, max-content));
			column-gap: clamp(1em, 3vw, 3em);
		}
		& > li {
			line-height: 1.5;
			a {
				display: block;
				color: $txt_c;
				position: relative;
				@include dec-none;
				@include f-w(700);
				@include media-breakpoint-up(lg) {
					@include fs_lg(11, 18);
				}
				@include media-breakpoint-up(xl) {
					font-size: 1.14em;
				}
				&:hover {
					@include dec-line;
				}
			}
			.f-nav__items__lv2 {
				margin-top: 0.5em;
				font-size: 0.94em;
				li {
					a {
						display: block;
						padding-left: 1.25em;
						@include f-w(500);
						&::before {
							position: absolute;
							top: 0.75em;
							left: 0;
							content: "";
							width: 0.7em;
							height: 1px;
							background: $main_c;
						}
					}
				}
			}
		}
	}
}
.f-nav__items {
	> .f-nav__item {
		&-1 {
			grid-row: 1 / 2;
			grid-column: 1;
		}
		&-2 {
			grid-row: 1 / span 2;
			grid-column: 2;
		}
		&-3 {
			grid-row: 1 / 2;
			grid-column: 3;
		}
		&-4 {
			grid-row: 1 / span 2;
			grid-column: 4;
		}
		&-5 {
			grid-row: 1 / span 2;
			grid-column: 4;
			margin-top: 2em;
		}
		&-6 {
			grid-row: 1 / 2;
			grid-column: 5;
		}
	}
}
/* pagetop
--------------------------------------------------------------------*/
.pagetop {
	position: absolute;
	z-index: z-index(pagetop);
	bottom: 4.5rem;
	right: map-get($contmargin, sp);
	@include media-breakpoint-up(md) {
		right: map-get($contmargin, tb);
	}
	@include media-breakpoint-up(xl) {
		right: map-get($contmargin, pc);
	}
	@include media-breakpoint-up(full) {
		right: map-get($contmargin, full);
	}
	& a {
		display: block;
		width: 48px;
		height: 48px;
		color: $white;
		background: $main_c;
		display: flex;
		align-items: center;
		justify-content: center;
		@include radius(50%);
		@include dec-none;
		@include f-size(24);
		@include transition;
		@include media-breakpoint-up(lg) {
			width: 60px;
			height: 60px;
			@include f-size(36);
		}
		&:hover {
			background: $hover_c;
		}
	}
	i {
		vertical-align: top;
	}
}
