@use "../_variables/variables" as *;
@use "effects" as *;
@use "others" as *;
@use "text" as *;

@mixin btn_base {
	font-weight: normal;
	text-align: center;
	text-decoration: none;
	position: relative;
	line-height: 1.4;
	@include f-w(700);
	@include transition;
	@include dec-none;
}

// btn_normal
@mixin btn_normal(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $hover_c,
	$border_h: $hover_c
) {
	display: block;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1em 4%;
	width: 100%;
	@include btn_base;
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
	}
}

// btn_arrow
@mixin btn_arrow(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$arrow: $main_c,
	$arrowbg: $white,
	$color_h: $white,
	$bg_h: $hover_c,
	$border_h: $hover_c,
	$arrow_h: $hover_c,
	$arrowbg_h: $white
) {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	column-gap: 0.5em;
	align-items: center;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1em 4%;
	width: 100%;
	font-size: 1.25em;
	@include btn_base;
	@include radius(999px);
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		i {
			background: $arrowbg_h;
			&::before {
				color: $arrow_h;
			}
		}
	}
	&::before {
		content: "";
		justify-self: start;
	}
	i {
		position: relative;
		width: 32px;
		height: 32px;
		content: "";
		background: $arrowbg;
		justify-self: end;
		@include radius(50%);
		@include transition;
		&::before {
			font-size: 0.8em;
			color: $arrow;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include transition;
		}
	}
}

// btn_line
@mixin btn_line($color: $main_c, $bg: $white, $border: $main_c, $color_h: $white, $bg_h: $point_c, $border_h: $black) {
	display: block;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1em 4%;
	width: 100%;
	@include btn_base;
	@include f-w(500);
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		&::after {
			width: 4em;
			background-color: $border_h;
		}
	}
	&::after {
		position: absolute;
		top: 50%;
		left: calc(100% - 1.5em);
		width: 3em;
		height: 1px;
		content: "";
		background: $border;
		@include transition;
	}
}
