@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: z-index(header);
  width: 100%;
  background: transparent;
  @include transition(all, 0.3, ease-in, 0);
  @include header-down {
    height: $header-hight_sp;
  }
  &.js-fixed {
    background: $white;
    box-shadow: 0 0 10px rgba($black, 0.1);
    .h-left {
      padding-top: 0.75em;
      @include header-up {
        max-width: 200px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 220px;
      }
      @include header-down {
        max-width: #{$h_logo_sp}px;
      }
    }
  }
}

.h- {
  &inner {
    position: relative;
    @include m-a;
  }
  &flex {
    align-items: flex-start;
    @include flex-between;
  }
  &left {
    width: 70%;
    padding-top: 1em;
    margin-left: 2%;
    @include transition;
    @include header-up {
      max-width: #{$h_logo_pc}px;
    }
    @include header-down {
      max-width: #{$h_logo_sp}px;
    }
    @include media-breakpoint-up(full) {
      max-width: floor-decimal(calc($h_logo_pc / 1920), 4) * 100vw;
    }
  }
  &logo {
    letter-spacing: 0;
    text-align: left;
  }
  &right {
    align-items: center;
    @include flex-end;
    @include header-down {
      display: none;
    }
  }
  &donation {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em 1.5em;
      height: 100%;
      min-height: 70px;
      color: $white;
      background: $main_c;
      font-size: 0.94em;
      @include line-h(1);
      @include transition;
      @include dec-none;
      @include media-breakpoint-up(xl) {
        min-height: 80px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.07em;
      }
      span {
        @include f-w(700);
        padding-right: 0.5em;
      }
      i {
        position: relative;
        width: 32px;
        height: 32px;
        content: "";
        background: $white;
        @include radius(50%);
        &::before {
          color: $main_c;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include transition;
        }
      }
      &:hover {
        background: $hover_c;
        i::before {
          color: $hover_c;
        }
      }
    }
  }
}

/*--------------------------------------------------------------------/
	navigation
/--------------------------------------------------------------------*/

.pc-nav {
  @include header-down(lg) {
    display: none;
  }
}
.pc-nav__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 60px;
  @include media-breakpoint-up(xl) {
    min-height: 80px;
  }
  & > li {
    & > a {
      vertical-align: middle;
      color: $txt_c;
      padding: 1em 1.5em;
      height: 100%;
      min-height: 70px;
      position: relative;
      font-size: 0.94em;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      @include line-h(1);
      @include f-w(700);
      @include transition;
      @include dec-none;
      @include media-breakpoint-up(xl) {
        padding: 1em 1.5em;
        min-height: 80px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.07em;
      }
      //&::after {
      //  content: "";
      //  background-color: $main_c;
      //  width: 0%;
      //  height: 1px;
      //  position: absolute;
      //  bottom: 0;
      //  left: 0;
      //  @include transition;
      //}
      &:hover {
        color: $white;
        background: $hover_c;
      }
    }
  }
}

//二階層メニュー
// サブメニュー　幅
$pc_submenu_w: 240; //px
.pc-lv2 {
  position: relative;
  &:hover {
    & .sub-menu {
      top: 80px;
      visibility: visible;
      opacity: 1;
    }
  }
  & .sub-menu {
    position: absolute;
    top: 60px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    left: 0;
    z-index: 10;
    width: 100%;
    min-width: #{$pc_submenu_w}px;
    @include transition;
    @include flex-column;
    @include media-breakpoint-up(xl) {
      top: 80px;
    }
    @include media-breakpoint-up(full) {
      width: floor-decimal(calc($pc_submenu_w / 1920), 4) * 100vw;
    }
    li {
      width: auto;
    }
    & a {
      display: flex;
      align-items: center;
      font-size: 0.94em;
      padding: 0.75em 1.25em;
      color: $white;
      line-height: 1.4;
      position: relative;
      background: $b-gray;
      height: 50px;
      @include dec-none;
      @include f-w(700);
      @include transition;
      @include media-breakpoint-up(xl) {
        height: 60px;
      }
      &:hover {
        background: $hover_c;
        color: $white;
      }
    }
  }
}

/*--------------------------------------------------------------------/
	modal
/--------------------------------------------------------------------*/
.body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes menu-bar02 {
  0% {
    transform: translateX(-12px);
    opacity: 0;
  }
  75% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes menu-bar03 {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
@keyframes active-menu-bar02 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    transform: translateX(12px);
    opacity: 0;
  }
  100% {
    transform: translateX(12px);
    opacity: 0;
  }
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}

/* スマホメニュー */
.close-btn {
  @include header-down {
    a {
      @include dec-none;
    }
  }
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: z-index(hamburger);
  @include header-up {
    display: none;
  }
  &-btn {
    position: relative;
    width: 64px;
    height: 64px;
    padding: 8px;
    cursor: pointer;
    display: block;
    transition: all 0.4s;
    box-sizing: border-box;
    background: $main_c;
    @include transition;
    &:hover {
      background: $hover_c;
    }
    & span {
      display: inline-block;
      transition: all 0.4s;
      box-sizing: border-box;
      position: absolute;
      left: 16px;
      width: 32px;
      height: 1px;
      background-color: $white;
      &:nth-of-type(1) {
        top: 21px;
        animation: menu-bar01 0.5s forwards;
      }
      &:nth-of-type(2) {
        top: 31px;
        animation: menu-bar02 0.5s forwards;
      }
      &:nth-of-type(3) {
        top: 41px;
        animation: menu-bar03 0.5s forwards;
      }
    }
    &.is-active {
      & span {
        &:nth-of-type(1) {
          animation: active-menu-bar01 0.5s forwards;
        }
        &:nth-child(2) {
          animation: active-menu-bar02 0.5s forwards;
        }
        &:nth-of-type(3) {
          animation: active-menu-bar03 0.5s forwards;
        }
      }
    }
  }
}

.sp-nav {
  @include header-up {
    display: none;
  }
  @include header-down {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $o-white;
    visibility: hidden;
    z-index: z-index(sp-nav);
    body.open & {
      cursor: "", auto;
      @include transition(0.5);
      visibility: visible;
      opacity: 1;
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding-bottom: clamp(4rem, 6vw, 6rem);
  }
  &__head {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    background: $white;
    width: 100%;
    z-index: z-index(module, part01);
    &.js-fixed {
      .sp-nav__ttl {
        padding-top: 0.75em;
      }
    }
  }
  &__ttl {
    width: 70%;
    padding-top: 1em;
    margin-left: 2%;
    @include header-up {
      max-width: #{$h_logo_pc}px;
    }
    @include header-down {
      max-width: #{$h_logo_sp}px;
    }
  }
  &__cont {
    padding-top: 5rem;
    @include media-breakpoint-up(md) {
      width: 80%;
      @include m-a;
    }
  }
}

// sp-nav__list
.sp-nav__list {
  & > li {
    width: 100%;
    margin: 0 auto;
    margin-top: -15px;
    text-align: left;
    opacity: 0;
    border-bottom: 1px solid $m-gray;
    line-height: 1.5em;
    width: 100%;
    & a {
      display: block;
      color: $txt_c;
      text-decoration: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 1.5em 5%;
      position: relative;
      font-size: 1.14em;
      @include f-w(700);
      @include media-breakpoint-up(md) {
        padding: 1.5em 0;
      }
    }
  }
}

//二階層メニュー
.sp-lv2 {
  position: relative;
  width: 100%;
  & > a[href="javascript:void(0);"] {
    &::before,
    &::after {
      font-style: normal;
      position: absolute;
      top: 50%;
      right: 1em;
      display: block;
      width: 14px;
      height: 1px;
      font-size: 1.14em;
      content: "";
      background-color: $gray;
      @include transition;
    }
    &::before {
      transform: rotate(0deg);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.is-active {
      &::before {
        display: none;
      }
      &::after {
        top: 50%;
        transform: rotate(180deg);
      }
    }
  }
  & .sub-menu {
    display: none;
    & li {
      border-top: 1px solid $white;
      background: $b-gray;
      a {
        color: $white;
        padding-left: calc(5% + 1.4em);
        position: relative;
        @include media-breakpoint-up(md) {
          padding-left: calc(1.5em + 1.4em);
        }
        &::before {
          position: absolute;
          content: "";
          top: 2.25em;
          left: 5%;
          width: 10px;
          height: 1px;
          background: $white;
          @include media-breakpoint-up(md) {
            left: 1.5em;
          }
        }
      }
    }
  }
}

body.open .sp-nav__list > li {
  opacity: 1;
  margin-top: 0;
  @for $num from 1 through 11 {
    &:nth-child(#{$num}) {
      transition: 0.5s ease (0.2s + calc($num / 10));
    }
  }
}

// .sp-nav
.sp-nav__donation {
  margin-top: -15px;
  padding-top: 1em;
  width: 90%;
  opacity: 0;
  @include m-a;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  body.open & {
    opacity: 1;
    margin-top: 0;
    @include transition(all, 0.5, ease, 1.2);
  }
  a {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 0.5em;
    align-items: center;
    color: $white;
    background-color: $main_c;
    border: 1px solid;
    padding: 1.25em 4%;
    width: 100%;
    font-size: 1.14em;
    @include btn-base;
    @include media-breakpoint-up(xl) {
      min-height: 80px;
    }
    &:hover {
      background: $hover_c;
      i::before {
        color: $hover_c;
      }
    }
    &::before {
      content: "";
      justify-self: start;
    }
    span {
      @include f-w(700);
    }
    i {
      position: relative;
      width: 32px;
      height: 32px;
      content: "";
      background: $white;
      justify-self: end;
      grid-column: 3 / 4;
      @include radius(50%);
      &::before {
        color: $main_c;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include transition;
      }
    }
  }
}

// クローズボタン
.sp-nav {
  &__close {
    margin-top: 2rem;
    opacity: 0;
    width: 90%;
    @include m-a;
    @include media-breakpoint-up(md) {
      width: 90%;
    }
    body.open & {
      opacity: 1;
      @include transition(all, 0.5, ease, 1.4);
    }
    a {
      text-align: center;
      border: 1px solid $gray;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray;
      height: 80px;
      line-height: 80px;
      background: $white;
      font-size: 1.25em;
      @include line-h(1.2);
      @include f-w(700);
      @include dec-none;
      @include f-family(font02);
      @include radius(999px);
      &::before {
        content: "×";
        margin-right: 0.3rem;
        @include f-family(font01);
      }
    }
  }
}
