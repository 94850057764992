@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	sns
/--------------------------------------------------------------------*/
// sns side
$side_icon_size: 32; // px
.sns-list__side {
	position: fixed;
	top: 40%;
	z-index: z-index(side-sns);
	@include media-breakpoint-down(sm) {
		display: none;
	}
	&.is-left {
		left: 0.5rem;
		li {
			transform: translateX(calc(#{-$side_icon_size}px - 10px));
			@include media-breakpoint-up(full) {
				transform: translateX((floor-decimal(calc($side_icon_size / 1920), 4) * -100vw) - 1vw);
			}
			&.is-active {
				@for $sns_num from 1 through 6 {
					&:nth-child(#{$sns_num}) {
						animation: 0.8s bounceInLeft (0.2s + calc($sns_num / 10)) forwards;
						@include media-breakpoint-up(full) {
							animation: 0.8s bounceInLeftFull (0.2s + calc($sns_num / 10)) forwards;
						}
					}
				}
			}
		}
	}
	&.is-right {
		right: 0.5rem;
		li {
			transform: translateX(calc(#{$side_icon_size}px + 1em));
			@include media-breakpoint-up(full) {
				transform: translateX((floor-decimal(calc($side_icon_size / 1920), 4) * 100vw) + 1vw);
			}
			&.is-active {
				@for $sns_num from 1 through 6 {
					&:nth-child(#{$sns_num}) {
						animation: 0.8s bounceInRight (0.2s + calc($sns_num / 10)) forwards;
						@include media-breakpoint-up(full) {
							animation: 0.8s bounceInRightFull (0.2s + calc($sns_num / 10)) forwards;
						}
					}
				}
			}
		}
	}
	ul {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	li {
		padding: 0.4rem 0;
		flex-shrink: 1;
		a {
			display: block;
			position: relative;
			line-height: 1;
			width: #{$side_icon_size}px;
			height: #{$side_icon_size}px;
			border-radius: 50%;
			background: $main_c;
			overflow: hidden;
			@include dec-none;
			@include transition;
			@include media-breakpoint-up(full) {
				width: floor-decimal(calc($side_icon_size / 1920), 4) * 100vw;
				height: floor-decimal(calc($side_icon_size / 1920), 4) * 100vw;
			}
			&::before {
				position: absolute;
				color: $white;
				top: 50%;
				left: 52%;
				transform: translate(-50%, -50%);
				font-size: 0.85em;
				text-align: center;
				vertical-align: middle;
				@include f-w(100);
			}
			&:hover {
				transform: scale(1.15, 1.15);
				background: $hover_c;
			}
			&.facebook {
				@include icon(facebook2);
				&::before {
					font-size: 1em;
				}
			}
			&.x_twitter {
				@include icon(x);
				&::before {
					font-size: 0.9em;
				}
			}
			&.instagram {
				@include icon(instagram);
			}
			&.Line {
				@include icon(line);
			}
			&.youtube {
				@include icon(youtube);
			}
			&.tiktok {
				@include icon(tiktok);
			}
		}
	}
}

@keyframes bounceInLeft {
	to {
		animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
	}
	from {
		transform: translateX(calc(#{-$side_icon_size}px - 1em));
	}
	40% {
		transform: translateX(16px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes bounceInLeftFull {
	to {
		animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
	}
	from {
		transform: translateX(floor-decimal(calc($side_icon_size / 1920), 4) * -100vw - 1vw);
	}
	40% {
		transform: translateX(16px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes bounceInRight {
	to {
		animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
	}
	from {
		transform: translateX(calc(#{$side_icon_size}px + 1em));
	}
	40% {
		transform: translateX(-16px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes bounceInRightFull {
	to {
		animation-timing-function: cubic-bezier(0.61, 0, 0.25, 1);
	}
	from {
		transform: translateX(floor-decimal(calc($side_icon_size / 1920), 4) * 100vw + 1vw);
	}
	40% {
		transform: translateX(-16px);
	}
	to {
		transform: translateX(0);
	}
}

// sns sp
$sp_icon_size: 36px;
.sns-list__sp {
	padding: 1.5rem 10% 0;
	display: flex;
	justify-content: center;
	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		opacity: 0;
		body.open & {
			opacity: 1;
			@include transition(all, 0.5, ease, 1.2);
			transition: 0.5s ease 1.2s;
		}
	}
	li {
		padding: 0.3rem;
		flex-shrink: 1;
		a {
			display: block;
			position: relative;
			line-height: 1;
			width: $sp_icon_size;
			height: $sp_icon_size;
			border-radius: 50%;
			background: $main_c;
			overflow: hidden;
			@include dec-none;
			@include transition;
			&::before {
				position: absolute;
				color: $white;
				top: 50%;
				left: 52%;
				transform: translate(-50%, -50%);
				font-size: 1.2em;
				@include f-w(400);
				text-align: center;
				vertical-align: middle;
			}
			&:hover {
				background: $hover_c;
			}
			&.facebook {
				@include icon(facebook2);
				&::before {
					font-size: 1.4em;
				}
			}
			&.x_twitter {
				@include icon(x);
				&::before {
					font-size: 1.2em;
				}
			}
			&.instagram {
				@include icon(instagram);
			}
			&.Line {
				@include icon(line);
			}
			&.youtube {
				@include icon(youtube);
			}
			&.tiktok {
				@include icon(tiktok);
			}
		}
	}
}

// sns footer
.sns-list__footer {
	display: flex;
	@include media-breakpoint-up(md) {
		column-gap: 1.5em;
		flex-shrink: 0;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 1rem;
		flex-direction: column;
	}
	a {
		color: $dl-gray;
		padding-left: 2rem;
		position: relative;
		@include f-size(20);
		@include dec-none;
		@include f-family(font02);
		@include f-w(400);
		@include transition;
		@include media-breakpoint-down(sm) {
			@include f-size(22);
			padding-left: 2.5rem;
		}
		&:hover {
			color: $gray;
		}
		&::before {
			position: absolute;
			left: 0;
			top: 0.4rem;
			@include f-w(100);
			font-size: 0.875em;
			@include media-breakpoint-down(sm) {
				top: 0.4rem;
			}
		}
		&.facebook {
			@include icon(facebook1);
		}
		&.x_twitter {
			@include icon(x);
		}
		&.instagram {
			@include icon(instagram);
		}
		&.Line {
			@include icon(line);
		}
		&.youtube {
			@include icon(youtube);
		}
		&.tiktok {
			@include icon(tiktok);
		}
	}
}
