@use "../_variables/variables" as *;
@use "break-points" as *;
// 	normal
//---------------------------------------------------

//default font-family
@mixin set_font-family {
	font-family:
		#{map-get($font-set, font01)},
		"Hiragino Sans",
		"ヒラギノ角ゴ ProN W3",
		"Hiragino Kaku Gothic ProN",
		"游ゴシック",
		"Yu Gothic",
		"游ゴシック体",
		YuGothicM,
		YuGothic,
		"メイリオ",
		Meiryo,
		sans-serif;
}

//font-family
@mixin f-family($fontname, $next: sans-serif) {
	$name: map-get($font-set, $fontname);
	font-family:
		#{$name},
		$next;
}

//font-size
@mixin f-size($size: 16, $media: pc) {
	font-size: round-decimal(calc($size / map-get($media-rem,$media)), 2) + rem;
}

//font-weight
@mixin f-w($weight: 400) {
	font-weight: $weight;
}

//letter-spacing
@mixin l-sp($l-sp: 0.1em) {
	letter-spacing: $l-sp;
}

//line-height
@mixin line-h($height: 1.75) {
	line-height: $height;
}

//スマホ・タブレット・PCでのデフォルトフォントサイズ
@mixin f-base {
	font-size: floor-decimal(calc(map-get($media-rem,sp) / 16), 4) * 100%;
	@include media-breakpoint-up(md) {
		font-size: floor-decimal(calc(map-get($media-rem,tb) / 16), 4) * 100%;
	}
	@include media-breakpoint-up(lg) {
		font-size: floor-decimal(calc(map-get($media-rem,pc) / 16), 4) * 100%;
	}
	@include media-breakpoint-up(full) {
		font-size: floor-decimal(calc(map-get($media-rem,pc) / map-get($grid-num,full)), 4) * 100vw;
	}
}

// font-size用
$fontbreak: (
	xxs: 280,
	//デバイス幅の最小値
	xs: map-get($grid-num, xs),
	sm: map-get($grid-num, sm),
	md: map-get($grid-num, md),
	lg: map-get($grid-num, lg),
	xl: map-get($grid-num, xl)
) !default;
@function fs_clamp($name, $minsize, $maxsize) {
	$min_width: map-get($fontbreak, $name);
	$val_min: $minsize * 1px;
	$val_vw: round-decimal(calc($minsize / $min_width) * 100) * 1vw;
	$val_max: $maxsize * 1px;
	@return clamp(#{$val_min}, #{$val_vw}, #{$val_max});
}

@mixin fs_xxs($minsize, $min_width) {
	font-size: fs_clamp(xxs, $minsize, $min_width);
}
@mixin fs_xs($minsize, $min_width) {
	font-size: fs_clamp(xs, $minsize, $min_width);
}
@mixin fs_sm($minsize, $min_width) {
	font-size: fs_clamp(sm, $minsize, $min_width);
}
@mixin fs_md($minsize, $min_width) {
	font-size: fs_clamp(md, $minsize, $min_width);
}
@mixin fs_lg($minsize, $min_width) {
	font-size: fs_clamp(lg, $minsize, $min_width);
}
@mixin fs_xl($minsize, $min_width) {
	font-size: fs_clamp(xl, $minsize, $min_width);
}

@mixin f-s_xxs($size, $wid) {
	font-size: calc(#{$size}rem + #{$wid} * (100vw - 280px) / #{map-get($grid-num, xxl) - 280});
}
@mixin f-s_xs($size, $wid) {
	font-size: calc(
		#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num, xs)}px) / #{map-get($grid-num, xxl) - map-get($grid-num, xs)}
	);
}
@mixin f-s_sm($size, $wid) {
	font-size: calc(
		#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num, sm)}px) / #{map-get($grid-num, xxl) - map-get($grid-num, sm)}
	);
}
@mixin f-s_md($size, $wid) {
	font-size: calc(
		#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num, md)}px) / #{map-get($grid-num, xxl) - map-get($grid-num, md)}
	);
}
@mixin f-s_lg($size, $wid) {
	font-size: calc(
		#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num, lg)}px) / #{map-get($grid-num, xxl) - map-get($grid-num, lg)}
	);
}
@mixin f-s_xl($size, $wid) {
	font-size: calc(
		#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num, xl)}px) / #{map-get($grid-num, xxl) - map-get($grid-num, xl)}
	);
}
